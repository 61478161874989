import React, { useEffect, useState } from 'react';
import downloadIcon from '../../assets/icons/icon_download_task.png';
import { Dialog, DialogActions, DialogContent,
        DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { WeeklyTaskContext } from './WeeklyTasks';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { IconButton } from "@mui/material";

const useStyles = makeStyles({
  modalTitle: {
    display: "inline-flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 12,
    padding: "29px 61px 0",
  },
  dialogPaperRoot: {
    minWidth: "60% !important",
  },
  dialogContent: {
    padding: "20px 61px 61px",
  },
  dialogActions: {
    justifyContent: "flex-start",
    padding: "10px 61px 50px"
  },
});

export const TaskDialog = (props) => {
  const classes = useStyles();
  const { data, modalIsOpen, setModalIsOpen } = props;

  return (
    <WeeklyTaskContext.Consumer>
      {({ updateCompletedTasks }) => (
        <Dialog
          onClose={() => { setModalIsOpen(false) }}
          aria-labelledby="simple-dialog-title"
          maxWidth="lg"
          open={modalIsOpen}
          classes={{ paper: classes.dialogPaperRoot }}>
          <DialogTitle disableTypography id="task-title" className={classes.modalTitle}>
            <div className="taskModalCategoryTitle">{data.title}</div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id="alert-dialog-description">
              <div className="taskModalTitle">{data.label}</div>
              <div className="taskModalItem" dangerouslySetInnerHTML={{ __html: data.html }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {
              data.isComplete 
                ? <div className="taskModalButtonComplete">Task Completed</div> 
                : <div onClick={() => {
                    updateCompletedTasks(data.title, data.itemId);
                    setModalIsOpen(false); }}
                    className="taskModalButton">
                    Complete Task
                  </div>
            }
            {
              data.downloadLink 
                ? <a href={data.downloadLink} target="_blank">
                    <IconButton className="taskModalDownloadIcon">
                      <DownloadForOfflineIcon style={{ fontSize: '50px', fill: '#e83a1f'}} />
                    </IconButton>
                  </a> 
                : null
            }
          </DialogActions>
        </Dialog>
      )}
    </WeeklyTaskContext.Consumer >
  );
}
