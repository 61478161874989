import React, { useState, useEffect, useContext } from 'react';
import { Avatar, List, ListItem, ListItemAvatar,
        ListItemSecondaryAction, ListItemText,
        makeStyles, Theme, createStyles } from '@material-ui/core';
import { AppContext } from '../App/App';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      font: "bold 16px Poppins, sans-serif",
      color: "black",
      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },
    listItemTextPrimary: {
      font: "600 36px Poppins, sans-serif",
      textTransform: "capitalize",
      color: "black",
    },
    listItemTextSecondary: { // Unused
      fontWeight: 400,
      fontSize: "18px",
      textAlign: "left",
      "-webkit-text-decoration-line": "underline",
      textDecorationLine: "underline",
      color: "#e83a1f",
      marginTop: "23px",
      cursor: "pointer",
    }
  }),
);

export const Nav = (props) => {
  const { title, subTitle, subTitleLink } = props;
  const classes = useStyles();
  const appContext = useContext(AppContext);

  let secondaryText = ""; // Unused
  if (subTitleLink && subTitle) {
    secondaryText =
      <a style={{ color: "inherit" }} target="_blank" href={subTitleLink}>
        {subTitle}
      </a>
  } else if (subTitle) {
    secondaryText = subTitle;
  }

  return (
    <List>
      <ListItem key={title}>
        <ListItemText id={title} primary={title} secondary={secondaryText}
          classes={{
            primary: classes.listItemTextPrimary,
            secondary: classes.listItemTextSecondary
          }} />
        <ListItemSecondaryAction>
          <div className={classes.avatarContainer}>
            <ListItemAvatar>
              <Avatar alt="" src={appContext.loggedInUserDetails?.image}
                style={{
                  margin: "0 0 8px 6px",
                  width: "60px",
                  height: "60px",
                }} />
            </ListItemAvatar>
            {appContext.loggedInUserDetails?.fullName}
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}