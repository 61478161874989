import { Avatar, CircularProgress, Typography as Text, IconButton, Switch } from "@mui/material";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import PrintIcon from '@mui/icons-material/Print';
import { makeStyles, Dialog } from "@material-ui/core";
import { groupBy, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ProgressTable } from "../../../../components/Table";
import { capitalizeFirstLetter } from "../../../../helpers/capitalize";
import { getInitials } from "../../../../helpers/getInitials";
import { getAthleteReports } from "../../../../util/requests/reports";
import { Nav } from "../../../../components/Nav/Nav";
import "./index.css";

import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import NightsStayRoundedIcon from '@mui/icons-material/NightsStayRounded';

const checkTrackStatus = (isFinished) => {
  if (isNull(isFinished)) return "Not Started";
  if (isFinished) return "Finished";
  if (!isFinished) return "In Progress";
};

const useStyles = makeStyles({
  paper: {
    width: "100vw",
    height: "100vh",
    background: "transparent",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100vw",
    maxHeight: "100vh",
  },
});

const CircularProgressWithLabel = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <CircularProgress size={130} thickness={4}
        className="athletesCircProg"
        variant="determinate" sx={{ color: "var(--restoic-red)" }}
        {...props} />
        <div className="athletesCircProgLabel">
          {`${Math.round(props.value)}%`}
        </div>
    </div>
  );
}

export const AthleteReport = () => {
  const { query, pathname } = useLocation();
  const [state, setState] = useState([]);
  const formId = pathname.split("/")[3];
  const classes = useStyles();
  // const [checked, setChecked] = useState(true); // <-- Dark Mode Toggle

  useEffect(() => {
    getAthleteReports(formId).then((res) => {
      setState(res);
    });
  }, [setState]);

  // DATA PROCESSING FOR INDIVIDUAL WEEKLY PROGRESS DATA - - - - - - - - - - - - - - - - - //
  let athleteTracks = []
  state.athleteTracks 
    ? athleteTracks = state.athleteTracks 
    : []
  let weekDict = {}
  let weekData = []
  for (let i of athleteTracks) {
    !weekDict[i.week]
      ? weekDict[i.week] = [{"finished": i.is_finished, "updated":i.updated_at}]
      : weekDict[i.week].push({"finished": i.is_finished, "updated":i.updated_at})
  }
  let allFinishedCount = 0
  let allProgressCount = 0
  let allToDoCount = 0
  for (let i in weekDict) {
    let finishedCount = 0
    let progressCount = 0
    let toDoCount = 0
    for (let j in weekDict[i]) {
      if (weekDict[i][j]["finished"] === true) {
        finishedCount++, allFinishedCount++
      } else if (weekDict[i][j]["finished"] !== true && weekDict[i][j]["updated"] !== null) {
        progressCount++, allProgressCount++
      } else if (weekDict[i][j]["updated"] === null) {
        toDoCount++, allToDoCount++
      }
    }
    weekData.push({
      weekNum: i, 
      weekFinished: Math.round((finishedCount/weekDict[i].length) * 100),
      weekProgress: Math.round((progressCount/weekDict[i].length) * 100),
      weekToDo: Math.round((toDoCount/weekDict[i].length) * 100),
    })
  }

  let allProgData = [
    {"Finished": allFinishedCount},
    {"In Progress": allProgressCount},
    {"To Do": allToDoCount},
  ]

  return (
    <div className="contentBox">
      {/* <div className="switchBox">
        <WbSunnyRoundedIcon style={checked ? {color: "var(--restoic-grey)"} : {}} />
        <Switch 
          color="default"
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          style={{
            color: "var(--restoic-red)",
          }}
        />
        <NightsStayRoundedIcon style={checked ? {color: "var(--restoic-grey)"} : {}} />
      </div> */}
      <div className="athletesHeaderContainer">
        <Avatar src={(query?.image)} sx={{ width: 100, height: 100 }}>
          <Text fontSize={38}>{getInitials(state?.fullName)}</Text>
        </Avatar>
        <h3 className="athletesHeaderTitle"
          // style={checked ? {color: "white"} : {}}
        >
          {capitalizeFirstLetter(state?.fullName)}
        </h3>
        <div className="athletesPrintButtonBox">
          <h3 
            // style={checked ? {color: "white"} : {}}
          >
            Print
          </h3>
          <IconButton onClick={() => window.print()} 
            sx={{ background: "rgb(220,220,220)",
              marginLeft: "10px",
              "&:hover": {
                background: "var(--restoic-red)",
                color: "white",
              }
            }}>
            <PrintIcon />
          </IconButton>
        </div>
      </div>
      <Nav />
      {state.length === 0 && (
        <Dialog open={true} classes={{ paper: classes.paper }} style={{position: 'relative'}}>
          <CircularProgress style={{ color: "white", position: 'absolute' }} size={160} />
          <div className="loadingLogo" />
        </Dialog>
      )}
      <h3 className="athletesContentTitle" style={{ marginBottom: -50 }}>Overview</h3>
      <div className="athletesOverviewBox">
        {allProgData.map(e => (
          <div className="athletesOverviewContainer">
            <h3 className="athletesCircBoxTitle">{Object.keys(e)}</h3>
            <hr className="divider"/>
            <div className="athletesOverviewContent">
              <CircularProgressWithLabel
                value={isNaN(state?.totalTracksCount) 
                  ? 0 
                  : Math.round((Object.values(e)/state?.totalTracksCount) * 100)} />
            </div>
          </div>
        ))}
        <div className="athletesOverviewContainer noDisplay" style={{ alignItems: "normal" }}>
          <h3 className="athletesCircBoxTitle" style={{textAlign: "center"}}>TOTAL PROGRESS</h3>
          <hr className="divider"/>
          <div className="athletesOverviewContent">
            {weekData?.map(e => (
              <div className="athletesProgBarBox">
                <Text sx={{ font: "bold 14px Poppins", marginLeft: "5%" }}>Week {e.weekNum}</Text>
                <div style={{ margin: "0 10px" }}>
                  <div className="athletesProgBar">
                    <div className="athletesProgBarRed" style={{ width: `${e.weekFinished}px` }} />
                  </div>
                </div>
                <Text sx={{ font: "bold 14px Poppins" }}>{e.weekFinished}%</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
      {Object.keys(groupBy(state?.athleteTracks, "week")).map((item, idx) => {
        const athleteTracks = groupBy(state?.athleteTracks, "week")[item];
        return (
          <div className="athletesProgTableBox">
            <h3 className="athletesContentTitle">Week {idx + 1}</h3>
            <ProgressTable rows={athleteTracks?.map(item => {
              return {
                title: item.title,
                status: checkTrackStatus(item.is_finished),
                date: item.updated_at,
              };
            })}/>
          </div>
        );
      })}
    </div>
  );
};
