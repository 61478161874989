import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import "./index.css";
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';

export const ReportsItem = ({
  title,
  formId = "",
  reportType = "survey",
  image = "",
  email = "",
  isSurvey,
}) => (
  <Link 
    to={{
      pathname: `/reports/${reportType}/${formId}`,
      state: {title: title}
    }}
    className="downloadItemWrapper" 
    style={{textDecoration: 'none'}}
  >
    {isSurvey 
      ? title === "Pre-Program Surveys" || title === "Post-Program Surveys"
        ? <>
            <ListRoundedIcon
              className="downloadItemIcon"
              style={{ fontSize: '60px' }} />
            <div 
              className="downloadItemName" 
              style={{ fontSize: '1rem', textTransform: 'uppercase' }}>
                {title}
            </div>
          </>
        : <>
            <AssessmentRoundedIcon
              className="downloadItemIcon"
              style={{ fontSize: '60px' }} />
            <div 
              className="downloadItemName" 
              style={{ fontSize: '1rem', textTransform: 'uppercase' }}>
                {title}
            </div>
          </>
      : <>
          <Avatar 
            src={image} 
            sx={{ width: 75, height: 75 }} />
          <div 
            className="downloadItemName" 
            style={{ marginTop: '20px' }}>
              {title}
          </div>
          <div className="downloadItemEmail">{email}</div>
        </>
    }
  </Link>
);
