import React from 'react';
import './index.css';
import checkImage from '../../assets/icons/PlanItemChecked.png';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const ChoosePlanItem = ({ numberOfWeeks, title, items, recommended, onClick, isActiveWeek }) => (
	<div className="choicePlanItemWrapper">
		<div className={isActiveWeek ? "choicePlanItemNumbOfWeeksLast" : "choicePlanItemNumbOfWeeks"}>
			<div className="choicePlanItemNumbOfWeeksText">
				{numberOfWeeks}
			</div>
			{isActiveWeek && (
        <div className="choicePlanItemTriangle">
          <StarRoundedIcon
            className="choicePlanItemStar"
            fontSize="medium"
          />
        </div>
			)}
		</div>
		<div className={isActiveWeek ? "choicePlanItemBodyLast" : "choicePlanItemBody"}>
			<div className="choicePlanItemTitleText">{title}</div>
			{items.map((item, key) => (
				<div className="choicePlanListItemWrapper" key={key}>
          <CheckRoundedIcon className="choicePlanListItemIcon" />
					<div className="choicePlanListItemTitle">
						{item}
					</div>
				</div>
			))}
			<div className={isActiveWeek ? "choicePlanItemButtonGradient" : "choicePlanItemButton"}
				onClick={onClick}>
				{isActiveWeek ? "ACTIVE" : "GET STARTED"}
			</div>
		</div>
	</div>
);

export default ChoosePlanItem;