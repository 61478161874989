import ApiInstance, {
  removeTokenFromHeader,
  setTokenIntoHeader,
} from "../../services/api";
import { isCoach } from "../../utils/checkIsCoach";
import { getAllTeamMembers } from "../../util/requests/smartCoach";

// Note: this first login should be kept track of in the database. anytime a user clears their localstorage then
// they will be prompted with the choosePlan which is not correct.
export const firstTimeLogin = (email) => {
  let loginArray = JSON.parse(localStorage.getItem("firstTimeLogin"));
  if (loginArray && loginArray.includes(email)) {
    return false;
  }
  loginArray = loginArray ? loginArray : [];
  loginArray.push(email);
  localStorage.setItem("firstTimeLogin", JSON.stringify(loginArray));
  return true;
};

export async function logout() {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  removeTokenFromHeader();
}

export async function login(email, password) {
  try {
    const login = await ApiInstance.post("/rest-auth/login/", {
      email,
      password,
    });
    const data = login.data;

    if (!isCoach(data)) return false;
    setTokenIntoHeader(data.key);
    // dispatch({ type: LOGIN_SUCCESS, payload: { token: data.key, user: data.user } });
    localStorage.setItem("auth", JSON.stringify({ loggedIn: true }));
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", JSON.stringify(data.key));
    getAllTeamMembers().then((res) =>
      localStorage.setItem("teamId", res.team[0]?.team)
    );
    // Blake Aug 16 - Disabled the page redirection logic for brand new users
    // if (firstTimeLogin(data.user.email)) {
    //   return dispatch(replace('/choosePlan'))
    // }

    return true;
  } catch (error) {
    throw error;
  }

  // ApiInstance.post('/rest-auth/login/', { email, password })
  //   .then(({ data }) => {
  //     if (!isCoach(data)) return dispatch({ type: SET_ERROR, payload: { field: 'non_field_errors', message: 'Access denied.' } });
  //     setTokenIntoHeader(data.key)
  //     appContext.setIsAuthenticated(true);
  //     dispatch({ type: LOGIN_SUCCESS, payload: { token: data.key, user: data.user } });
  //     localStorage.setItem('auth', JSON.stringify({ loggedIn: true }))
  //     localStorage.setItem('user', JSON.stringify(data.user));
  //     localStorage.setItem('token', JSON.stringify(data.key));
  //     if (firstTimeLogin(data.user.email)) {
  //       return dispatch(replace('/choosePlan'))
  //     }
  //     dispatch(replace('/'))
  //   })
  //   .catch(({ response: { data } }) => {
  //     dispatch({ type: RESET_ERRORS });
  //     const arrayErrors = Object.entries(data);
  //     const arrayWithMessages = arrayErrors.map(item => ({
  //       field: `${item[0]}`,
  //       message: item[1].toString(),
  //     }));
  //     arrayWithMessages.map(item =>
  //       dispatch({ type: SET_ERROR, payload: { field: item.field, message: item.message } }));
  //   })
}

export async function googleLogin(accessToken) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const params = new URLSearchParams();
    params.append("access_token", accessToken);
    const data = await ApiInstance.post("/rest-auth/google/", params, config);

    // if (redeem) {
    //   setTokenIntoHeader(data.key)
    //   localStorage.setItem('redeemActive', JSON.stringify(data.key));
    //   dispatch(replace('/redeem-code'))
    //   return;
    // }

    // Note: this is terrible, the logic for checking if a user is coach or not should live in the backend
    //       we should not be doing a check like this in the frontend.
    //       More importantly, is this even what we want? Why would there be a restriction that only allows
    //       coaches to log into the app.
    // if (!isCoach(data.data)) throw "invalid login, not a coach";

    setTokenIntoHeader(data.data.key);
    localStorage.setItem("auth", JSON.stringify({ loggedIn: true }));
    localStorage.setItem("user", JSON.stringify(data.data.user));
    localStorage.setItem("token", JSON.stringify(data.data.key));

    return data.data.user.email;
  } catch (error) {
    if (error.response) {
      console.log("Google Auth exception: ", error.response);
    }
    throw error;
  }
}
