import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// import PersonIcon from '@material-ui/icons/Person';
// import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { DialogContent, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function CompleteWeekModal(props) {
  const classes = useStyles();
  const { isOpen, setIsOpen, proceedToNextWeek } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={() => { setIsOpen(false) }} aria-labelledby="complete-week-dialog" open={isOpen}>
      <DialogContent style={{ padding: 61, textAlign: "center" }}>
        <div className="nextWeekModalItemLogo"></div>
        <div className="nextWeekModalTitle">Keep it up!</div>
        <div className="nextWeekModalItem">Ready to continue?</div>
        <Grid container
          justify="center"
          alignItems="center"
          spacing={4}>
          <Grid item sm={6}>
            <div
              className="taskModalButton"
              style={{ background: "#e3e7eb", color: "black" }}
              onClick={() => setIsOpen(false)}>STILL IN PROGRESS</div>
          </Grid>
          <Grid item sm={6}>
            <div
              className="taskModalButton"
              onClick={async () => {
                await proceedToNextWeek();
                setIsOpen(false);
              }}
            >START WEEK </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
