import React, { useContext, useState } from "react";

import { Avatar, createStyles, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { removeFromTeam, removeInvitedMember } from "../../util/requests/smartCoach";
import { AppContext } from "../App/App";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      padding: 0,
    },
    listItemContainer: {
      padding: "22px 0",
      borderTop: "1px solid rgba(0, 0, 0, 0.06)",
      "&:last-child": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
      }
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    listItemTextPrimary: {
      font: "600 18px Poppins, sans-serif",
      color: "var(--restoic-black)",
      marginLeft: 24,
    },
    listItemTextSubtitle: {
      display: "flex", 
      font: "18px Poppins, sans-serif",
      marginLeft: 24,
    },
  }),
);

export default function MemberItem(props) {

  const { member, setAllMemberStats, allMemberStats, setFeedbackInfo } = props;
  const userRole = member.role === "Member" ? "Athlete" : "Staff";
  const user = member.user;
  let userSummary = "";
  let userName = "";
  let userEmail = "";
  let userImage = "";

  if (user) {
    const userLicense = user.premium_to ? `Licensed to: ${user.premium_to}` : "Unlicensed";
    userName = `${user.first_name} ${user.last_name}`;
    userEmail = user.email
    userImage = user.image;
    userSummary = `${userRole} | ${userLicense} | ${userEmail}`;
  } else {
    userSummary = `${userRole} | Invitation sent`;
    userName = member.email;
  }
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const appContext = useContext(AppContext);
  const handleOptionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemoveUser = async (uid) => {
    if (appContext.loggedInUserDetails.id === uid) {
      setFeedbackInfo({
        open: true,
        type: "error",
        message: "You cannot remove yourself!"
      });
      return;
    }
    try {
      if (uid) {
        await removeFromTeam(uid);

        let updatedMembers = allMemberStats.team.filter((m) => {
          return m.user.id !== uid
        });
  
        setAllMemberStats({"team": updatedMembers, "invited": allMemberStats.invited});
      } else {
        if (member.email) {
          await removeInvitedMember(member.email);
        }

        let updatedMembers = allMemberStats.invited.filter((m) => {
          return m.email !== member.email
        });
  
        setAllMemberStats({"team": allMemberStats.team, "invited": updatedMembers});
      }

      setFeedbackInfo({
        open: true,
        type: "success",
        message: "User successfully removed."
      });
    } catch (error) {
      setFeedbackInfo({
        open: true,
        type: "error",
        message: "An error occured when trying to deactivate that team member."
      });
    }
  }

  return (
    <>
      <ListItem classes={{
          root: classes.listItemRoot,
          container: classes.listItemContainer
        }}
        key={userName}
      >
        <ListItemAvatar>
          <Avatar className={classes.large} src={userImage} />
        </ListItemAvatar>
        <ListItemText classes={{
            primary: user 
              ? classes.listItemTextPrimary 
              : classes.listItemTextSubtitle,
            secondary: classes.listItemTextSubtitle
          }}
          primary={userName}
          secondary={userSummary}
        /> 
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={handleOptionsOpen}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={anchorEl}
            onClose={() => { setAnchorEl(null) }}
          >
            <MenuItem 
              onClick={() => {
                handleRemoveUser(user ? user.id : null);
                setAnchorEl(null)}}
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
                Remove
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}