import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ApiInstance from '../../services/api';
import { WeeklyTaskContext } from './WeeklyTasks';
import { getStatistics } from '../../util/requests/smartCoach';
import { AppContext } from '../App/App';

const getNextDayOfWeek = (date, dayOfWeek) => {
  var resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

  const months = { 
    0: 'January', 1: 'February', 2: 'March', 3: 'April',
    4: 'May', 5: 'June', 6: 'July', 7: 'August',
    8: 'September', 9: 'October', 10: 'November', 11: 'December'
  }
  const weekdays = {
    0: "Sunday", 1: "Monday", 2: "Tuesday",
    3: "Wednesday", 4: "Thursday", 5: "Friday",
    6: "Saturday"
  }
  const year = resultDate.getFullYear();
  const dt = resultDate.getDate();
  const monthIndex = resultDate.getMonth()
  const monthName = months[monthIndex];
  const weekIndex = resultDate.getDay()
  const weekday = weekdays[weekIndex]
  const formatted = `${weekday}, ${monthName} ${dt}, ${year}`;
  return formatted;
}

export const WeeklyTeamProgress = (props) => {
  const { stats, completedTasks, currDisplayedWeek } = props;
  const [totalTasksCompleted, setTotalTasksCompleted] = useState(0);
  const weeklyTaskContext = useContext(WeeklyTaskContext);
  const appContext = useContext(AppContext);
  const estimatedWeekly = getNextDayOfWeek(new Date(), 4);

  useEffect(() => {
    let totalCompleted = completedTasks[currDisplayedWeek - 1] ? completedTasks[currDisplayedWeek - 1].totalCompleted : 0;
    if (totalCompleted > 6) {
      totalCompleted = 6;
    }
    setTotalTasksCompleted(totalCompleted)
  }, [completedTasks, currDisplayedWeek]);

  return (
    <div className="weeklyProgressBox">
      <Grid>
        <Grid item>
          <div className="weeklyTasksStatusProgressLabel">weekly progress</div>
          <div className="weeklyProgressBoxSubtitle">{estimatedWeekly}</div>
          <hr className="divider"/>
        </Grid>
        <Grid item style={{ width: 172, margin: "auto" }}>
          <CircularProgressbarWithChildren
            value={totalTasksCompleted}
            styles={buildStyles({
              pathColor: "var(--restoic-red)",
              trailColor: "#F8F9FB",
              backgroundColor: "var(--restoic-grey)",
              pathTransitionDuration: 0.5,
            })}
            strokeWidth={7}
            background
            minValue={0}
            maxValue={6}
          >
            <div className="graphChildren">
              <div className="weeklyTasksStatusGraphPercentage">
                {`${totalTasksCompleted} / 6`}
              </div>
              <div className="weeklyTasksStatusGraphSubtitle">
                Tasks Completed
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </Grid>
      </Grid>
    </div>
  )
}
