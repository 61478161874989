import React, { useContext } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Grid } from '@material-ui/core';

import { useHistory } from 'react-router-dom';


export default function ProgramFinishedModal(props) {
  const { isOpen, setIsOpen } = props;

  const history = useHistory();

  return (
    <Dialog onClose={() => { setIsOpen(false) }} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogContent style={{ padding: 61, textAlign: "center" }}>
        <div className="nextWeekModalItemLogo"></div>
        <div className="nextWeekModalTitle">Congratulations!</div>
        <div className="nextWeekModalItem">Would you like to begin another training program or learn more about our resources for teams?</div>

        <Grid container
          justify="center"
          alignItems="center"
          spacing={4}>
          <Grid item sm={6}>
            <div
              className="taskModalButton"
              style={{ background: "#e3e7eb", color: "black" }}
              onClick={() => {
                setIsOpen(false);
                history.push("/choosePlan")
              }}>
              CHOOSE PROGRAM
            </div>
          </Grid>
          <Grid item sm={6}>
            <div
              className="taskModalButton"
              onClick={async (e) => {
                window.location = "mailto:sales@restoic.com";
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              LEARN MORE
            </div>
          </Grid>        </Grid>
      </DialogContent>
    </Dialog>
  );
}
