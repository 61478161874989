import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "56px 58px",
    height: "100%",
    boxSizing: "border-box",
    backgroundColor: "#f0f1f3",
    overflowY: "auto",
  },
}));

export const Content = (props) => {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      {props.children}
    </main>
  )
}