import * as React from "react";
import { Table, TableBody, TableCell, 
        TableContainer, TableHead, TableRow,
        Paper, Typography } from "@mui/material"

export const ProgressTable = ({ rows }) => {
  const months = { 
    1: 'January', 2: 'February', 3: 'March', 4: 'April',
    5: 'May', 6: 'June', 7: 'July', 8: 'August',
    9: 'September', 10: 'October', 11: 'November', 12: 'December'
  }

  return (
    <TableContainer
      component={Paper}>
      <Table aria-label="simple table">
        <colgroup>
          <col style={{ width: "40%" }} />
          <col style={{ width: "40%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="gray" fontWeight="bold">Track Name</Typography>
            </TableCell>
            <TableCell>
              <Typography color="gray" fontWeight="bold">Status</Typography>
            </TableCell>
            <TableCell>
              <Typography color="gray" fontWeight="bold">Date</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography fontWeight="bold">{row.title}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">{row.status}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" fontSize={14}>
                  {row.date 
                    ? `${months[parseInt(row.date?.split("T")[0].substr(5,2))]} 
                      ${row.date?.split("T")[0].substr(8,1) === '0' 
                        ? row.date?.split("T")[0].substr(9,1) 
                        : row.date?.split("T")[0].substr(8,2)}, 
                          ${row.date?.split("T")[0].substr(0,4)}` 
                  : "--"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
