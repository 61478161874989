import React, { useEffect, useState } from 'react';
import ApiInstance, { removeTokenFromHeader, setTokenIntoHeader } from '../../services/api';
import Auth from '../../components_old/Auth';
import Input from '../../components_old/Input';
import './index.css';
import { withRouter } from 'react-router';
import InputMask from 'react-input-mask';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { TWILIO_URL } from '../../config';

const Redeem = ({ history: { replace } }) => {
  const [redeem, setRedeem] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redeemActive, setRedeemActive] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [smsPhone, setSmsPhone] = useState('');
  const [smsSendEnabled, setSmsSendEnabled] = useState(false);
  const [smsDone, setSmsDone] = useState(false);
  const [smsSendingInProgress, setSmsSendingInProgress] = useState(false);
  const [smsMessage, setSmsMessage] = useState('');

  // useEffect(() => {
  //   const readyForReedem = JSON.parse(localStorage.getItem('redeemActive'));
  //   if (readyForReedem === null) {
  //     replace('/redeem')
  //   }
  // }, []);

  const resetErrors = () => {
    setError(false);
    setErrorMessage('');
    setSuccessMessage(false);
  };

  const smsAction = async () => {
    setSmsSendingInProgress(false);
    setSmsDone(false);
    setSmsMessage('');
    const phone = '+' + smsPhone.replace(/[^0-9\.]/g, '');

    if (phone.length === 12) {  // +1 and 10-digit phone
      console.log("Sending SMS to phone: ", phone);
      setSmsSendingInProgress(true);
      axios.post(TWILIO_URL, { To: phone }).then((response) => {
        setSmsSendingInProgress(false);
        setSmsDone(true);
        if (response?.status === 200) {
          console.log("SMS response", response?.message);
          setSmsMessage('We have just texted you the app.');
        }
      }).catch(() => {
        setSmsSendingInProgress(false);
        setSmsMessage('Something went wrong.  Please use the helpdesk tool for support.');
      });
    };
  };

  const redeemAction = async () => {
    resetErrors();
    ApiInstance.post('/api/v1/code', { code: redeem }).then((response) => {
      if (response?.data?.error) {
        setError(true);
        setErrorMessage(response?.data?.error);
        setSuccessMessage(false);
        return;
      }
      if (response?.data?.message) {
        resetErrors();
        setRedeemActive(true);
        removeTokenFromHeader();
        setSuccessMessage('Congratulations, you have redeemed your Restoic VIP code!');
      }
    }).catch(() => {
      setError(true);
      setErrorMessage('Something went wrong');
      setSuccessMessage(false);
    });
  };

  const redirectToDownloadLink = (link) => {
    window.open(link, '_blank')
  }

  const onPhoneChange = (event) => {
    setSmsMessage('');
    setSmsPhone(event.target.value);

    const phone = event.target.value.replace(/[^0-9\.]/g, '');

    if (phone.length === 11) {
      setSmsSendEnabled(true);
    } else {
      setSmsSendEnabled(false);
    }
  }

  return (
    <Auth>
      <div className="redeemContainer">
        {successMessage ? (
          <>
            <div className="successMessage">{successMessage}</div>
            <span className="downloadText">Enter your mobile number to download the app on your smartphone.</span>
            <div className="">
              <InputMask
                alwaysShowMask={true}
                mask='(+1) 999 999 9999'
                onChange={onPhoneChange}
                value={smsPhone}
                className="defaultInput phoneInput"
                // placeholder="Enter in your mobile phone number"
                pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                required
              />    
            </div>
            {smsSendingInProgress ? (
              <CircularProgress style={{ color: "white" }} size={54} />
            ) : null}
            {successMessage ? (
              <span className="downloadText">{smsMessage}</span>
            ) : null}
            {smsDone === false ? (
              <div
                onClick={() => {
                  smsAction();
                }}
                className={smsSendEnabled ? 'redeemButtonActive' : 'redeemButton'}>
                TEXT ME
              </div>
            ) : (
              <a 
                className="redeemButtonActive"
                href="https://www.restoic.com">
                Done
              </a>
            )}
            )
          </>
        ) : (
          <>
            <Input
              onChange={({ target: { value } }) => setRedeem(value)}
              value={redeem}
              className="defaultInput"
              error={error}
              errorMessage={errorMessage}
              placeholder="Enter in your VIP code"
            />
            <div
              onClick={() => {
                redeemAction();
              }}
              className={redeemActive ? 'redeemButtonActive' : 'redeemButton'}>
              REDEEM
            </div>
          </>
        )}
      </div>
    </Auth>
  );
};

export default withRouter(Redeem);
