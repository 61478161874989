import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
// components
import Input from '../../components_old/Input';
import ResponseFeedback from '../../components/Feedback/ResponseFeedback';

// style
import './index.css'
import { useHistory } from 'react-router-dom';
import Auth from '../../components_old/Auth';
import { registerUser } from '../../util/requests/smartCoach';

import {
  Snackbar,
  IconButton,
  Dialog,
  makeStyles,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const intialErrors = {
  email: false,
  password: false,
  firstName: false,
  lastName: false,
}

const useStyles = makeStyles({
  paper: {
    width: "100vw",
    height: "100vh",
    background: "transparent",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100vw",
    maxHeight: "100vh",
    padding: 0,
    margin: 0,
  }
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RegisterUser(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({ ...intialErrors });
  const [isLoading, setIsLoading] = useState(false);

  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    type: "",
    message: "",
  });

  const classes = useStyles();
  const history = useHistory();

  const clearInputs = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
  }

  const onRegisterSubmit = async () => {
    let currErrors = { ...intialErrors };
    if (email === "") currErrors.email = true;
    if (password.trim() === "") currErrors.password = true;
    if (firstName.trim() === "") currErrors.firstName = true;
    if (lastName.trim() === "") currErrors.lastName = true;

    if (currErrors.email || currErrors.password || currErrors.firstName || currErrors.lastName) {
      setErrors(currErrors);
      return;
    }

    setIsLoading(true);
    try {
      debugger;
      await registerUser(email, password, firstName, lastName);
      clearInputs();
      setFeedbackInfo({
        open: true,
        type: "success",
        message: "Account created successfully."
      });
      history.push("/login");
    } catch (err) {
      setFeedbackInfo({
        open: true,
        type: "error",
        message: "An error occured when trying to create your account."
      });
    }

    setIsLoading(false);
  }

  return (
    <>
      <Dialog open={isLoading} classes={{ paper: classes.paper }}>
        <CircularProgress style={{ color: "white" }} size={54} />
      </Dialog>
      <ResponseFeedback feedbackInfo={feedbackInfo} setFeedbackInfo={setFeedbackInfo} />
      <Auth hasRedeem={false}>
        <div className="signUpRedeemContent">
          <div className="signUpRedeemCenter">
            <Input
              onChange={({ target: { value } }) => setEmail(value)}
              value={email}
              type="text"
              error={errors.email}
              errorMessage="Invalid Email"
              className="signUpRedeemInput"
              placeholder="Email"
            />
            <Input
              onChange={({ target: { value } }) => setFirstName(value)}
              value={firstName}
              type="text"
              error={errors.firstName}
              errorMessage="First Name cannot be blank!"
              className="signUpRedeemInput"
              placeholder="First Name"
            />
            <Input
              onChange={({ target: { value } }) => setLastName(value)}
              value={lastName}
              type="text"
              error={errors.lastName}
              errorMessage="Last Name cannot be blank!"
              className="signUpRedeemInput"
              placeholder="Last Name"
            />
            <Input
              onChange={({ target: { value } }) => setPassword(value)}
              value={password}
              type="password"
              error={errors.password}
              errorMessage="Invalid Password"
              className="signUpRedeemInput"
              placeholder="Password"
            />
            <div
              onClick={onRegisterSubmit}
              className="signUpRedeemButton"
            >
              Sign up
            </div>
            <div className="signUpRedeemTermsText">
              By using Restoic you agree to our&nbsp;
              <span
                className="signUpRedeemTermsLabel"
                onClick={() => alert('go to terms page')}
              >Terms</span>
            </div>
            <div
              className="signUpRedeemDontHaveAccountText"
              onClick={() => props.history.push('/login')}
            >
              Have an account? Log in
            </div>
          </div>
        </div>
      </Auth>
    </>
  );
}
