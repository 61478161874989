import React from "react";
import { Nav } from '../../components/Nav/Nav';
import { ReportsItem } from "./components/ReportsItem";
import "./index.css";

export const Reports = () => {
  return (
    <div className="contentBox">
      <Nav title="Reports" subTitle="" />
      <div className="reportsContent">
        <ReportsItem title="Pre-Program Surveys" formId="GEJVQjYe" isSurvey={true}/>
        <ReportsItem title="Post-Program Surveys" formId="jsL3MkAe" isSurvey={true}/>
        <ReportsItem title="Athlete Progress Reports" reportType="athletes" isSurvey={true}/>
      </div>
    </div>
  );
};
