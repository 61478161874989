import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { makeStyles, Dialog, CircularProgress } from "@material-ui/core";
import { IconButton } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { Nav } from "../../../../components/Nav/Nav";
import { getTypeformReports } from "../../../../util/requests/reports";
import "./index.css";

const useStyles = makeStyles({
  paper: {
    width: "100vw",
    height: "100vh",
    background: "transparent",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100vw",
    maxHeight: "100vh",
  },
});

export const Surveys = (props) => {
  const location = useLocation()
  const {title} = location.state
  const [state, setState] = useState([]);
  const formId = location.pathname.split("/")[3];
  const styles = useStyles();

  useEffect(() => {
    getTypeformReports(formId).then((res) => {
      setState(res.response)
    })
  }, [setState]);

  const months = { 
    1: 'January', 2: 'February', 3: 'March', 4: 'April',
    5: 'May', 6: 'June', 7: 'July', 8: 'August',
    9: 'September', 10: 'October', 11: 'November', 12: 'December'
  }

  return (
    <div className="contentBox">
      <Nav title={title} />
      <div className="surveysPrintButtonBox">
        <h3 style={{ marginRight: "10px" }}>Print</h3>
        <IconButton onClick={() => window.print()} 
          sx={{ background: "rgb(220,220,220)",
            "&:hover": {
              background: "var(--restoic-red)",
              color: "white",
            }
          }}>
          <PrintIcon />
        </IconButton>
      </div>
      <div className="surveysContent">
        {state.length === 0 && (
          <Dialog open={true} classes={{ paper: styles.paper }}>
            <CircularProgress style={{ color: "white", position: 'absolute' }} size={160} />
            <div className="loadingLogo" />
          </Dialog>
        )}
        {state.map(item => (
          <div className="surveysBox">
            <div className="surveysHeader">
              <h3 className="surveysNameDate">{item.name}</h3>
              <h3 className="surveysNameDate">
                {`${months[parseInt(item.date?.split("T")[0].substr(5,2))]} 
                ${item.date?.split("T")[0].substr(8,1) === '0' ?
                  item.date?.split("T")[0].substr(9,1) :
                  item.date?.split("T")[0].substr(8,2)}, 
                ${item.date?.split("T")[0].substr(0,4)}`}
              </h3>
            </div>
            <div style={{ padding: '20px' }}>
              {item.answers.map(e => (
                <div className="surveysQABox">
                  <h3 className="surveysQuestion">{e.question}</h3>
                  <h3 className="surveysAnswer">{e.answer}</h3>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
