import ApiInstance from "../../services/api";

export async function getChallengeData() {
  try {
    const req = await ApiInstance.get("api/v1/selected-challenge");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function createChallenge(challengeId, startDate) {
  try {
    const req = await ApiInstance.post("api/v1/selected-challenge", {
      challenge: challengeId,
      start_date: startDate,
    });
    const createdChallengeData = await getChallengeData();

    localStorage.setItem(
      "activeProgram",
      JSON.stringify({
        ...createdChallengeData,
        preSurveyOpened: false,
        postSurveyOpened: false,
      })
    );
    getAllTeamMembers().then((res) =>
      localStorage.setItem("teamId", res.team[0]?.team)
    );
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function getStatistics() {
  try {
    const req = await ApiInstance.get("api/v1/statistic-task");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllTeamMembers() {
  try {
    const req = await ApiInstance.get("api/v1/team-members");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllMemberStatistics() {
  try {
    const req = await ApiInstance.get("api/v1/statistic-member");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function getDownloads() {
  try {
    const req = await ApiInstance.get("api/v1/challenge-files");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function inviteTeamMember(email, memberType) {
  try {
    const req = await ApiInstance.post("api/v1/invite", {
      email,
      memberType,
    });
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function removeInvitedMember(email) {
  try {
    const req = await ApiInstance.post("api/v1/uninvite", {
      email,
    });
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function progressToNextWeek() {
  try {
    const req = await ApiInstance.post("api/v1/progressToNextWeek");
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function updateCompletedTasksRequest(completedTasks) {
  completedTasks = JSON.stringify(completedTasks);
  try {
    const req = await ApiInstance.put("api/v1/completeTask", {
      completedTasks,
    });
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function removeFromTeam(uid) {
  try {
    const req = await ApiInstance.put(`api/v1/user/${uid}/removeFromTeam`);
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function registerUser(email, password, first_name, last_name) {
  try {
    const req = await ApiInstance.post("/rest-auth/registration/", {
      email,
      password,
      first_name,
      last_name,
    });
    return req.data;
  } catch (error) {
    throw error;
  }
}
