import React, {useState, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { AppBar, Container, Toolbar } from '@material-ui/core';
// components
import Input from '../../components_old/Input';
//actions
import {sendForgotPasswordEmailAction} from '../../modules/auth';
// style
import './index.css';
import {rememberMeGet} from '../../utils/rememberMe';
import {withRouter} from 'react-router-dom';

const mainActions = {
  sendForgotPasswordEmailAction: sendForgotPasswordEmailAction,
};

const ForgotPasswordContainer = (props) => {
  const [email, setEmail] = useState(rememberMeGet());
  const [error, setError] = useState('');

  const validateEmail = () => {
    const error = {};
    if (!email) {
      error.email = 'Email field can not be empty';
      error.isError = true;
      setError({...error});
      return;
    }
    setError({...error});
    props
      .sendForgotPasswordEmailAction(email)
      .then((response) => {
        if (response.status === 200) {
          error.isError = false;
          error.email = response.data.detail;
          setError({...error});
        } else {
          error.isError = true;
          error.email = `We are sorry, there is a problem resetting your password.  Please contact support team via live chat in the corner to troubleshoot the issue.`;
          console.log("Send forgot password exception details: ", JSON.stringify(response));
          setError({...error});
        }
      })
      .catch((errorMessage) => {
        error.isError = true;
        error.email = errorMessage;
        setError({...error});
      });
  };

  return (
    <div className="mainContainer">
      <AppBar position="relative" style={{ background: "black" }}>
        <Toolbar style={{ justifyContent: "center" }}>
          <div className="loginHeaderLogo" />
        </Toolbar>
      </AppBar>
      <div className="forgotPasswordContainer">
        <div className="forgotPasswordCenter">
          <div className="forgotPasswordCenterWelcomeText">
            Forgotten your password? Enter your e-mail address below, and we'll send you an e-mail allowing you to
            reset it.
          </div>
          <div>
            <Input
              onChange={({target: {value}}) => setEmail(value)}
              value={email}
              type="text"
              className="inputContainer"
              placeholder="Enter email"
            />
            {error.email ? (
              <div className={error.isError ? 'errorInput' : 'successInputMessage'}>{error.email}</div>
            ) : null}
          </div>
          <div className="sendEmailButtonContainer">
            <div onClick={() => validateEmail()} className="sendEmailButton">
              SEND EMAIL
            </div>
          </div>

          <div className="forgotPasswordTermsText">
            By using Restoic you agree to our&nbsp;
            <span className="forgotPasswordTermsLabel" onClick={() => alert('go to terms page')}>
              Terms
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    ({
      auth: {
        authenticated,
        error,
        loading,
        emailError,
        passwordError,
        emailErrorText,
        passwordErrorText,
        non_field_errorsErrorText,
        non_field_errorsError,
      },
    }) => ({
      authenticated,
      error,
      loading,
      emailError,
      passwordError,
      emailErrorText,
      passwordErrorText,
      non_field_errorsErrorText,
      non_field_errorsError,
    }),
    (dispatch) => bindActionCreators(mainActions, dispatch),
  )(ForgotPasswordContainer),
);
