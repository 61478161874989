import React, { useEffect, useState, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import AppleLogin from "react-apple-login";
// components
import Input from "../../components_old/Input";
// actions
import {
  googleSignAction,
  loginAction,
  resetErrorAction,
  signUpApple,
} from "../../modules/auth";
// style
import "./index.css";
import { rememberMeGet, rememeberMeAdd } from "../../utils/rememberMe";
import { useHistory, withRouter } from "react-router-dom";

import { googleLogin, login } from "../../util/requests/auth";
import { AppContext } from "../App/App";
import {
  AppBar,
  Container,
  Toolbar,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { firstTimeLogin } from "../../utils/firstTimeLogin";

import { BASE_API_URL } from "../../config";
import {
  getChallengeData,
  updateCompletedTasksRequest,
} from "../../util/requests/smartCoach";

const useStyles = makeStyles({
  paper: {
    width: "100vw",
    height: "100vh",
    background: "transparent",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100vw",
    maxHeight: "100vh",
    padding: 0,
    margin: 0,
  },
});

export default function Login(props) {
  const [email, setEmail] = useState(rememberMeGet());
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const ssoLoginDisabled = true;

  const classes = useStyles();

  function validate() {
    let isValid = true;
    resetErrors();

    if (!email) {
      setErrorEmail(true);
      setErrorEmailMessage("Please enter your email address.");
      isValid = false;
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setErrorEmail(true);
        setErrorEmailMessage("Please enter valid email address.");
        isValid = false;
      }
    }

    if (!password) {
      setError(true);
      setErrorMessage("Please enter your password.");
      isValid = false;
    }

    if (typeof password !== "undefined") {
      if (password.length < 6) {
        setError(true);
        setErrorMessage("Please enter valid password.");
        isValid = false;
      }
    }

    return isValid;
  }

  const resetErrors = () => {
    setError(false);
    setErrorEmail(false);
    setErrorMessage("");
    setErrorEmailMessage("");
  };

  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (props.authenticated) {
      props.history.replace("/");
    }
    resetErrorAction();
  }, []);

  const onEmailPasswordSubmit = async () => {
    setIsLoading(true);

    if (rememberMe) {
      rememeberMeAdd(email);
    }

    let isValid = validate();
    if (isValid) {
      try {
        const loginSuccessful = await login(email, password);
        if (loginSuccessful) {
          const selectedChallenge = await getChallengeData();
          appContext.setIsAuthenticated(true);
          if (selectedChallenge.error && selectedChallenge.error !== "") {
            // there is no challenge added for the team, so we prompt them to the choose program page.
            history.push("/choosePlan");
          } else {
            appContext.setProgramSelected(true);
            history.push("/home");
          }
        }
      } catch (error) {
        setError(true);
        setErrorMessage("Unable to log in with provided credentials");
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="mainContainer">
      <Dialog open={isLoading} classes={{ paper: classes.paper }}>
        <CircularProgress style={{ color: "white" }} size={54} />
      </Dialog>

      <AppBar position="relative" style={{ background: "black" }}>
        <Toolbar style={{ justifyContent: "center" }}>
          <div className="loginHeaderLogo" />
        </Toolbar>
      </AppBar>
      <div className="loginContainer">
        <div className="loginCenter">
          <div className="loginCenterWelcomeText">
            Welcome to Restoic Smart Coach. Please log in below.
          </div>
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onEmailPasswordSubmit();
              }
            }}
            onChange={({ target: { value } }) => setEmail(value)}
            value={email}
            type="text"
            error={errorEmail}
            errorMessage={errorEmailMessage}
            className="loginInput"
            placeholder="Email"
          />
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onEmailPasswordSubmit();
              }
            }}
            onChange={({ target: { value } }) => setPassword(value)}
            value={password}
            type="password"
            error={error}
            errorMessage={errorMessage}
            className="loginInput"
            placeholder="Enter password"
          />
          <div className="loginCenterRememberWrapper">
            <div className="loginCenterRememberMe">
              <input
                type="checkbox"
                value={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                className="rememberMeInput"
              />
              <div className="loginCenterRememberMeText">Remember me</div>
            </div>
            <div
              className="loginCenterForgotPassword"
              onClick={() => props.history.push("/password-reset")}
            >
              Forgot password?
            </div>
          </div>
          <div onClick={onEmailPasswordSubmit} className="loginButton">
            LOGIN
          </div>
          {ssoLoginDisabled === false ? (
            <>
              <AppleLogin
                clientId="com.restoic.smartcoach"
                redirectURI="https://app.restoic.com/login"
                responseType={"code"}
                responseMode={"form_post"}
                usePopup={true}
                callback={(res) => {
                  if (res?.error) {
                    return;
                  }
                  props.signUpAppleAction(
                    res.authorization.code,
                    res.authorization.id_token
                  );
                }}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="appleButton"
                  >
                    <div className="appleButtonIcon" />
                    <div className="appleButtonText">Login with Apple</div>
                  </div>
                )}
              />
              <GoogleLogin
                clientId="489520710630-rr02mkdas6vbs8v6abciiri05mappsvt.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={async ({ tokenObj: { access_token } }) => {
                  setIsLoading(true);
                  try {
                    const email = await googleLogin(access_token);
                    appContext.setIsAuthenticated(true);
                    const firstLogin = firstTimeLogin(email);
                    if (email && firstLogin) {
                      history.push("/choosePlan");
                    } else {
                      history.push("/home");
                    }
                  } catch (error) {
                    appContext.setIsAuthenticated(false);
                    debugger;
                    if (error.response && error.response.status === 400) {
                      setError(true);
                      setErrorMessage(
                        "This account is not linked with Google, please enter email / password to sign in."
                      );
                    }
                  }

                  setIsLoading(false);
                }}
                onFailure={(response) => {
                  setIsLoading(false);
                  console.log("error");
                }}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="googleButton"
                  >
                    <div className="googleButtonIcon" />
                    <div className="googleButtonText">Login with Google</div>
                  </div>
                )}
              />
            </>
          ) : null}
          <div className="loginTermsText">
            By using Restoic you agree to our&nbsp;
            <span
              className="loginTermsLabel"
              onClick={() =>
                window.open("https://restoic.com/pages/terms-conditions")
              }
            >
              Terms
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
