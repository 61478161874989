import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.css';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FolderSpecialRoundedIcon from '@mui/icons-material/FolderSpecialRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

export default function SidebarItem(props) {
  const { label, path, isActive } = props;
  const history = useHistory();
  let sideBarIcon = null

  switch (label) {
    case 'Weekly Tasks':
      sideBarIcon = <HomeRoundedIcon style={{fontSize: '30px'}} />;
      break;
    case 'Team Progress':
      sideBarIcon = <DonutLargeRoundedIcon style={{fontSize: '30px'}} />;
      break;
    case 'Downloads':
      sideBarIcon = <DownloadForOfflineIcon style={{fontSize: '30px'}} />;
      break;
    case 'Programs':
      sideBarIcon = <FolderSpecialRoundedIcon style={{fontSize: '30px'}} />;
      break;
    case 'Reports':
      sideBarIcon = <AssessmentRoundedIcon style={{fontSize: '30px'}} />;
      break;
    case 'Team Members':
      sideBarIcon = <GroupRoundedIcon style={{fontSize: '30px'}} />;
      break;
  }

  return (
    <div className="sideBarItemWrapper" onClick={() => history.push(path)}>
      <div className="sideBarIconAndLabel">
        <div className="sideBarIcon" style={isActive ? {color: '#f5573e'} : {}}>
          {sideBarIcon}
        </div>
        <div className="sideBarLabel" style={isActive ? {color: '#f5573e'} : {}}>{label}</div>
      </div>
      <div className="sideBarActiveDash" style={isActive ? {height: "33px"} : {}} />
    </div >
  );
};
