import React from "react";

import { createStyles, IconButton, makeStyles, Snackbar, Theme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
  }),
);

export default function ResponseFeedback(props) {
  const { feedbackInfo, setFeedbackInfo } = props;
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={feedbackInfo.open ? true : false}
      autoHideDuration={5000}
      onClose={() => {
        setFeedbackInfo({
          open: false,
          type: "",
          message: "",
        })
      }}
    >
      <Alert
        style={{ alignItems: "center" }}
        severity={feedbackInfo.type}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() => {
                setFeedbackInfo({
                  open: false,
                  type: "",
                  message: "",
                })
              }}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        {feedbackInfo.message}
      </Alert>
    </Snackbar>

  )
}