import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText,
        DialogTitle, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  modalTitle: {
    display: "inline-flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 12,
    padding: "29px 61px 0",
  },
  dialogPaperRoot: {
    minWidth: "60% !important",
  },
  dialogContent: {
    padding: "20px 61px 61px",
  },
  dialogActions: {
    justifyContent: "flex-start",
    padding: "0px 61px 61px"
  },
});

export const CoachCornerDialog = (props) => {
  const classes = useStyles();
  const { data, modalIsOpen, setModalIsOpen } = props;

  return (
    <Dialog
      onClose={() => { setModalIsOpen(false) }}
      aria-labelledby="simple-dialog-title" 
      maxWidth="lg" 
      open={modalIsOpen} 
      classes={{ paper: classes.dialogPaperRoot }}
    >
      <DialogTitle disableTypography id="task-title" className={classes.modalTitle}>
        <div className="coachInsightIcon" />
        <div className="taskModalCategoryTitle">{data.title}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          <div className="taskModalTitle">{data.label}</div>
          <div className="taskModalItem" dangerouslySetInnerHTML={{ __html: data.html }}></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div onClick={() => { setModalIsOpen(false) }} className="taskModalButton">
          Done
        </div>
      </DialogActions>
    </Dialog>
  );
}