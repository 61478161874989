import React, { useEffect, useState } from "react";
import { Nav } from "../../../../components/Nav/Nav";
import { ReportsItem } from "./../../components/ReportsItem";
import { getAllMemberStatistics } from "../../../../util/requests/smartCoach";
import "./index.css";

export const Athletes = () => {
  const [state, setState] = useState([]);

  useEffect(() => {
    getAllMemberStatistics().then((res) => {
      setState(res.map((user) => user.user.user));
    });
  }, [setState]);

  return (
    <div className="contentBox">
      <Nav title="Athletes" />
      <div className="athletesContent">
        {state?.map((item) => (
          <ReportsItem
            key={item.id}
            title={`${capitalize(item.first_name)} ${capitalize(item.last_name)}`}
            formId={item.id}
            image={item.image}
            email={item.email}
            isSurvey={false}
            reportType="athletes" />
        ))}
      </div>
    </div>
  );
};

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}