import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Login from "../../components/Login/Login";
import WeeklyTasks from "../../components/WeeklyTasks/WeeklyTasks";
import { Downloads } from "../../components/Downloads/Downloads";
import TeamProgress from "../../components/TeamProgress/TeamProgress";
import ChoosePlan from "../../containers/ChoosePlan";
import ViewCourse from "../../components/Course";
import Redeem from "../../containers/Redeem";
import ReedemLogin from "../../containers/ReedemLogin";
import ReedemSignUp from "../../containers/ReedemSignUp";
import ForgotPasswordContainer from "../../containers/ForgotPassword";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import Sidebar from "../SideBar/Sidebar";
// import ComingSoon from "../../components_old/ComingSoon";
import PrivateRoute from "../../components_old/PrivateRoute";
import { isLoggedIn } from "../../util/helpers";
import useScript from "../../useScript";
import { Admin } from "../Admin/Admin";
import { Box } from "@material-ui/core";
import {
  getAllTeamMembers,
  getChallengeData,
  getStatistics,
} from "../../util/requests/smartCoach";
import RegisterUser from "../Registration/RegisterUser";
import { Reports } from "../../containers/Reports";
import { Surveys } from "../../containers/Reports/components/Surveys";
import { Athletes } from "../../containers/Reports/components/athletes";
import { AthleteReport } from "../../containers/Reports/components/athleteReport";
import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
}));

export const AppContext = React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  programSelected: false,
  setProgramSelected: () => {},
  loggedInUserDetails: {},
  setSurveyInProgress: () => {},
  surveyInProgress: false,
});

export const App = (props) => {
  useScript("hs-script-loader", "//js.hs-scripts.com/8212596.js");
  const { window } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn());
  const [programSelected, setProgramSelected] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState();
  const [currWeek, setCurrWeek] = useState("");
  const [surveyInProgress, setSurveyInProgress] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const getData = async () => {
        try {
          const selectedChallenge = await getChallengeData();
          if (selectedChallenge.error && selectedChallenge.error !== "") {
            setProgramSelected(false);
            history.push("/choosePlan");
          } else {
            setProgramSelected(true);
          }
        } catch (error) {}
      };

      getData();
      getAllTeamMembers().then((res) => setTeamId(res.team[0]?.team));

      const getCurrWeek = async () => {
        const { current_week } = await getStatistics();
        setCurrWeek(current_week);
      };

      try {
        const { first_name, last_name, image, id, email } = JSON.parse(
          localStorage.getItem("user")
        );

        const fullName = `${first_name} ${last_name}`;
        setLoggedInUserDetails({
          fullName,
          image,
          id,
          email,
          teamId,
        });
      } catch (error) {
        setLoggedInUserDetails({
          fullName: "",
          image: "",
          id: 0,
        });
      }

      getCurrWeek();
    }
  }, [isAuthenticated, setTeamId]);

  return (
    <AppContext.Provider
      value={{
        currWeek,
        setCurrWeek,
        isAuthenticated,
        setIsAuthenticated,
        loggedInUserDetails,
        setProgramSelected,
        programSelected,
        surveyInProgress,
        setSurveyInProgress,
      }}
    >
      <Box className={classes.root} width={1} height={1}>
        {
          isAuthenticated && programSelected && !surveyInProgress 
            ? <Sidebar />
            : null
        }
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/password-reset" component={ForgotPasswordContainer} />
          <PrivateRoute path="/choosePlan" component={ChoosePlan} />
          {
            isAuthenticated 
              ? <>
                  <PrivateRoute exact path="/">
                    <Redirect to="/home" />
                  </PrivateRoute>
                  <PrivateRoute path="/home" component={WeeklyTasks} />
                  <PrivateRoute path="/teamProgress" component={TeamProgress} />
                  <PrivateRoute path="/viewCourse" component={ViewCourse} />
                  <PrivateRoute path="/downloads" component={Downloads} />
                  <PrivateRoute path="/admin" component={Admin} />
                  <PrivateRoute exact path="/reports" component={Reports} />
                  <PrivateRoute path="/reports/survey/:id" component={Surveys} />
                  <PrivateRoute exact path="/reports/athletes" component={Athletes} />
                  <PrivateRoute exact path="/reports/athletes/:id" component={AthleteReport} />
                </>
              : <PrivateRoute exact path="/" component={Login} />
          }
          <Route path="/redeem-code" component={Redeem} />
          <Route path="/redeem" component={ReedemLogin} />
          <Route path="/redeem-signUp" component={ReedemSignUp} />
          <Route path="/register" component={RegisterUser} />
        </Switch>
      </Box>
    </AppContext.Provider>
  );
}

function ComingSoonWrapper({ title }) {
  const { first_name, last_name, image } = JSON.parse(
    localStorage.getItem("user")
  );
  return (
    <div className="comingSoon">
      <NavHeader
        title={title}
        userImage={image}
        firstName={first_name}
        lastName={last_name}
        hasSlider={false}
      />
      <ComingSoon />
    </div>
  );
}

function RedirectToCrowdbotics({ path }) {
  useEffect(() => {
    window.location.href = "https://restoic.herokuapp.com" + path;
  }, []);
  return <React.Fragment></React.Fragment>;
}
