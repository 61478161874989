const productionConfig = {
  BASE_API_URL: 'https://api.restoic.com/',
  DOMAIN_URL: 'https://app.restoic.com/',
  TWILIO_URL: 'https://restoic-5971.twil.io/sms-download',
  V2_API_URL: 'https://api2.restoic.com/'
};

const devConfig = {
  BASE_API_URL: 'https://api.restoic.com/',
  DOMAIN_URL: 'http://localhost:3000',
  TWILIO_URL: 'https://restoic-5971.twil.io/sms-download',
  V2_API_URL: 'https://api2.restoic.com/'
  // V2_API_URL: 'http://localhost:8080'
};

export const {
  BASE_API_URL,
  DOMAIN_URL,
  TWILIO_URL,
  V2_API_URL,
} = process.env.NODE_ENV === 'production' ? productionConfig : devConfig;

