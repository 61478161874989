import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CoachCornerDialog } from './CoachCornerModal';

const useStyles = makeStyles({
  root: {
    borderRadius: "8px",
    border: "none",
    boxShadow: "0 18px 18px rgba(25, 31, 79, 0.13)",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.02)",
    },
  },
  title: {
    textAlign: "center",
    font: "bold 1.25rem Poppins, sans-serif"
  },
  subtitle: {
    textAlign: "center",
    marginBottom: 12,
    fontFamily: "Poppins, sans-serif"
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between"
  },
});

export const WeeklyCoachCorner = (props) => {
  const classes = useStyles();
  const { activities } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState("");

  let html = "";
  activities.forEach((activity) => {
    html += `${activity.task} </br>`;
  });

  return (
    <div>
      <CoachCornerDialog data={modalData} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <Card 
        onClick={() => {
          setModalData({
            html,
            label: "Coach's Corner",
            title: "Weekly Smart Coach Insight",
          });
          setModalIsOpen(true);
        }}
        className={classes.root}>
        <CardContent style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0 18px 18px rgba(25, 31, 79, 0.13)",
        }}>
          <Typography variant="h6" className={classes.title}>
            COACH'S CORNER
          </Typography>
          <Typography className={classes.subtitle} color="textSecondary">
            Weekly Smart Coach Insight
          </Typography>
          <hr className="divider"/>
          <div className="coachCornerContent" 
            dangerouslySetInnerHTML={{ __html: html }}>
          </div>
          <div className="coachCornerAction">VIEW</div>
        </CardContent>
      </Card>
    </div>
  )
}
