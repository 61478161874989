import React, { useContext, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { getPercentageOfNumber } from '../../utils/getPercentageOfNumber';
import { changeWeek, getCurrentSelectedChallenge } from '../../modules/challenges';
import { getMemberStats, getTeamStats } from '../../modules/stats';
import iconFilterMember from '../../assets/icons/icon_filterMember.png';
import { createStyles, Grid, makeStyles, Select, MenuItem } from '@material-ui/core';
import { MemberStats } from './MemberStats';
import { Content } from '../Content';
import { Nav } from '../Nav/Nav';
import { AppContext } from '../App/App';
import './index.css';

const mainActions = {
  getCurrentSelectedChallengeAction: getCurrentSelectedChallenge,
  getTeamStatsAction: getTeamStats,
  changeWeekAction: changeWeek,
  getMemberStatsAction: getMemberStats
}

const useStyles = makeStyles((Theme) =>
  createStyles({
    gridContainerRoot: {
      marginTop: 34,
      backgroundColor: Theme.palette.background.paper,
      padding: "36px 62px",
      borderRadius: "4px",
      boxShadow: "0 18px 18px rgb(25 31 79 / 13%)",
      overflow: "auto",
    },
    teamProgressSelector: {
      font: "600 18px Poppins, sans-serif",
      textTransform: "uppercase",
      color: "var(--restoic-black)",
      margin: "5px",
      padding: "10px",
    }
  }),
);

const getNextDayOfWeek = (date, dayOfWeek) => {
  var resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

  // should probably use moment.js, but for now lets keep the package size small
  const months = { 
    0: 'January', 1: 'February', 2: 'March', 3: 'April',
    4: 'May', 5: 'June', 6: 'July', 7: 'August',
    8: 'September', 9: 'October', 10: 'November', 11: 'December'
  }
  const weekdays = {
    0: "Sunday", 1: "Monday", 2: "Tuesday",
    3: "Wednesday", 4: "Thursday", 5: "Friday",
    6: "Saturday"
  }
  const year = resultDate.getFullYear();
  const dt = resultDate.getDate();
  const monthIndex = resultDate.getMonth()
  const monthName = months[monthIndex];
  const weekIndex = resultDate.getDay()
  const weekday = weekdays[weekIndex]
  const formatted = `${weekday}, ${monthName} ${dt}, ${year}`;
  return formatted;
}

const TeamProgress = ({
  user: { first_name: firstName, last_name: lastName, image },
  changeWeekAction,
  currentWeek,
  numberOfWeeks,
  start_date = null,
  getTeamStatsAction,
  getCurrentSelectedChallengeAction,
  estimatedWeekly = getNextDayOfWeek(new Date(), 4),  // default to the closest Thurs
  weeklyProgress,
  getMemberStatsAction,
  memberStats
}) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [sortAction, setSortAction] = useState("Most Active");
  const [allMemberStats, setAllMemberStats] = useState([]);
  const [reportingWeeks, setReportingWeeks] = useState([]);

  useEffect(() => {
    getTeamStatsAction();
    getCurrentSelectedChallengeAction();
    getMemberStatsAction();
  }, [])

  useEffect(() => {
    var weeks = [];
    for (var i = 1; i <= appContext.currWeek; i++) {
      weeks.push(i);
    }
    setReportingWeeks(weeks);
  }, [appContext.currWeek])

  useEffect(() => {
    setAllMemberStats(memberStats);
  }, [memberStats])

  const teamProgressPercentage = getPercentageOfNumber(weeklyProgress.done + weeklyProgress.in_progress, weeklyProgress.total_members);

  const sortTeamProgress = () => {
    if (sortAction === "Name") {
      let sortedStats = memberStats.sort(function(a, b) {
        return (b.weekly.done + b.weekly.in_progress) - (a.weekly.done + a.weekly.in_progress)
      });
      setAllMemberStats(sortedStats);
      setSortAction("Most Active");
    } else {
      let sortedStats = memberStats.sort(function(a, b) {
        let x = a.user.user.first_name.toLowerCase() + a.user.user.last_name.toLowerCase();
        let y = b.user.user.first_name.toLowerCase() + b.user.user.last_name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return a.user.user.first_name >= b.user.user.first_name
      });
      setAllMemberStats(sortedStats);
      setSortAction("Name");
    }
  }

  const setCurrentWeek = (week) => {
    getMemberStatsAction(week);
    changeWeekAction(week);
  }

  return (
    <Content>
      <Grid container>
        <Grid item sm={12}>
          <Nav title="Team Progress" subTitle="" />
        </Grid>
        <Grid container sm={12} classes={{ root: classes.gridContainerRoot }}>
          <Grid item sm={12} md={12}>
            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
              <Grid item style={{ marginBottom: "-10px" }}>
                <Select
                  classes={{ root: classes.teamProgressSelector }}
                  fullWidth
                  id="weekSelector"
                  value={currentWeek}
                  disableUnderline
                  onChange={(e) => setCurrentWeek(e.target.value)}>
                {reportingWeeks.map(week => (
                  <MenuItem classes={{ root: classes.teamProgressSelector }}
                    value={week}
                    key={week}>
                      Week {week} Progress
                  </MenuItem>
                ))}
                </Select>
              </Grid>
              <Grid item className="teamProgressGraphSubtitle">Estimated Completion Date:</Grid>
              <Grid item className="teamProgressGraphDate">
                  {estimatedWeekly}
              </Grid>
              <hr className="divider"/>
              <Grid item className="teamProgressGraphWrapper">
                <CircularProgressbarWithChildren
                  value={teamProgressPercentage}
                  styles={buildStyles({
                    pathColor: "var(--restoic-red)",
                    trailColor: "#F8F9FB",
                    backgroundColor: "var(--restoic-grey)",
                    pathTransitionDuration: 0.5,
                  })}
                  strokeWidth={7}
                  background
                  minValue={0}
                  maxValue={100}>
                  <div>
                    <div className="teamProgressGraphPercentage">
                      {Math.round(teamProgressPercentage)}%
                    </div>
                    <div className="teamProgressGraphPercentageSubtitle">
                      weekly team progress
                    </div>
                  </div>
                </CircularProgressbarWithChildren>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <div className="teamProgressMemberWrapper">
              <div className="teamProgressMemberFilterHeader">
                <div className="teamProgressMemberFilterTitle">ATHLETE PROGRESS</div>
                <div className="teamProgressFilterWrapper" onClick={() => sortTeamProgress()}>
                  <div className="teamProgressFilterLabel">
                    {sortAction}
                  </div>
                  <img alt="filter" src={iconFilterMember} className="teamProgressFilterIcon" />
                </div>
                <div className="teamProgressDummyDiv" />
              </div>
              <div className="teamProgressMemberStatsWrapper">
                {allMemberStats.map((item, key) => (
                  <MemberStats
                    id={item.user.user.id}
                    key={key}
                    firstName={capitalize(item.user.user.first_name)}
                    lastName={capitalize(item.user.user.last_name)}
                    image={item.user.user.image}
                    weeklyProgress={item.weekly}
                    isLastItem={memberStats.length - 1 === key}
                  />
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  )
}

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default withRouter(connect(
  ({
    auth: {
      user,
    },
    challenges: {
      currentWeek,
      numberOfWeeks,
      challegeData: {
        start_date
      },
    },
    stats: {
      weeklyProgress,
      memberStats
    }
  }) => ({
    user,
    currentWeek,
    numberOfWeeks,
    start_date,
    weeklyProgress,
    memberStats
  }),
  dispatch => bindActionCreators(mainActions, dispatch)
)(TeamProgress));
