import React, { useContext, useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./index.css";
import { useHistory, useLocation } from "react-router-dom";
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import SidebarItem from "./SidebarItem";
import { logoutAction } from "../../modules/auth";
import { logout } from "../../util/requests/auth";
import { AppContext } from "../App/App";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: 270,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 90,
    },
    ['@media print']: {
      width: 0,
    }
  },
}));

export default function Sidebar(props) {
  const {} = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const [currPath, setCurrPath] = useState(history.location.pathname);

  useEffect(() => {
    setCurrPath(location.pathname);
  }, [location]);

  const mainNavItems = [
    {
      label: "Weekly Tasks",
      path: "/home",
    },
    {
      label: "Team Progress",
      path: "/teamProgress",
    },
    {
      label: "Downloads",
      path: "/downloads",
    },
    {
      label: "Programs",
      path: "/choosePlan",
    },
    {
      label: "Reports",
      path: "/reports",
    },
  ];

  const adminNavItems = [
    {
      label: "Team Members",
      path: "/admin",
    },
  ];

  const handleLogout = () => {
    logout();
    appContext.setIsAuthenticated(false);
    history.push("/login");
  };

  const drawer = (
    <div className="navItemContainer">
      <div>
        <div className="sideBarLogo" onClick={() => history.push("/home")} />
        {mainNavItems.map(sideBarItem => (
          <div
            key={sideBarItem.label}
            onClick={() => {setCurrPath(sideBarItem.path)}}>
            <SidebarItem
              onClick={() => {setCurrPath(sideBarItem.path)}}
              label={sideBarItem.label}
              path={sideBarItem.path}
              isActive={currPath.includes(sideBarItem.path)} />
          </div>
        ))}
      </div>
      <div>
        <Divider />
        {adminNavItems.map((sideBarItem) => (
          <div
            key={sideBarItem.label}
            onClick={() => {setCurrPath(sideBarItem.path)}}>
            <SidebarItem
              label={sideBarItem.label}
              path={sideBarItem.path}
              isActive={sideBarItem.path === currPath} />
          </div>
        ))}
        <div
          className="sideBarItemWrapper"
          onClick={() => {handleLogout()}}>
          <div className="sideBarIconAndLabel">
            <ExitToAppRoundedIcon className="sideBarIcon" style={{fontSize: '30px'}} />
            <div className="sideBarLabel">
              LOGOUT
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="navigation options">
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawer }}>
        {drawer}
      </Drawer>
    </nav>
  );
}
