import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles, Button, Grid, 
        MobileStepper, IconButton, Snackbar, Dialog, CircularProgress } from "@material-ui/core";
import logoIcon from "../../assets/icons/icon_task_logo.png";
import loginBackground from "../../assets/images/Login_Background.jpg";
import EventNoteIcon from '@mui/icons-material/EventNote';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import { Tasks } from "./Tasks";
import { Nav } from "../Nav/Nav";
import { WeeklyCoachCorner } from "./WeeklyCoachCorner";
import { WeeklyTeamProgress } from "./WeeklyTeamProgress";
import { getChallengeData, getStatistics, progressToNextWeek,
        updateCompletedTasksRequest } from "../../util/requests/smartCoach";
import { Content } from "../Content";
import CompleteWeekModal from "../Modals/CompleteWeekModal";
import ProgramFinishedModal from "../Modals/ProgramFinishedModal";
import { AppContext } from "../App/App";
import Alert from "@material-ui/lab/Alert";
import useTypeForm from "../../hooks/useTypeForm";
import { TypeFormWrapper } from "../../components/TypeForm/TypeFormWrapper";
import "./index.css";

const tasks = [
  {
    groupItems: [
      {
        label: "Weekly Tasks",
        actionIcon: <EventNoteIcon style={{ fontSize: '40px' }} />,
        isOrange: false,
        id: 2,
        status: "inProgress",
        typeName: "Weekly Task",
      },
      {
        label: "Print & Review",
        actionIcon: <DownloadForOfflineIcon style={{ fontSize: '40px' }} />,
        isOrange: false,
        id: 3,
        status: "inProgress",
        typeName: "Print and review",
      },
    ],
    title: "COACH TASKS",
  },
  {
    groupItems: [
      {
        label: "Homework",
        actionIcon: <EventNoteIcon style={{ fontSize: '40px' }} />,
        isOrange: false,
        id: 1,
        status: "completed",
        typeName: "Homework Task",
      },
      {
        label: "Print & Provide",
        actionIcon: <DownloadForOfflineIcon style={{ fontSize: '40px' }} />,
        isOrange: false,
        id: 7,
        status: "completed",
        typeName: "Print and provide",
      },
    ],
    title: "ATHLETE TASKS",
  },
  {
    groupItems: [
      {
        label: "Weekly Team Activity",
        actionIcon: logoIcon,
        isOrange: true,
        id: 4,
        status: "inProgress",
        typeName: "Team Activity",
      },
      {
        label: "Weekly Discussion Points",
        actionIcon: logoIcon,
        isOrange: true,
        id: 5,
        status: "inProgress",
        typeName: "Team Discussion",
      },
    ],
    title: "TEAM ACTIVITIES",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  item: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  rightPane: {
    borderLeft: "1px solid #ECECEC",
    paddingTop: "90px",
    paddingLeft: "36px",
    [theme.breakpoints.down("md")]: {
      borderLeft: "none",
      paddingLeft: 0,
    },
  },
  paper: {
    width: "100vw",
    height: "100vh",
    background: "transparent",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100vw",
    maxHeight: "100vh",
  },
}));

export const WeeklyTaskContext = React.createContext({
  setDemoData: () => {},
});

const StyledMobileStepper = withStyles({
  root: {
    padding: 0,
    background: "transparent",
  },
  progress: {
    overflow: 'hidden !important',
    width: "90%",
    height: "10px",
    margin: "5px",
    transform: "skew(-45deg)",
    borderRadius: "2px",
    backgroundColor: "var(--restoic-grey)",
    "& *": {
      backgroundColor: "var(--restoic-red)",
    },
  },
})(MobileStepper);

// TODO: refactor api
// NOTE: these responses are not ideal, we will want to redo all of the api's we have.
//       the most straight forward way for now to link task sections to the respective data is by
//       matching the title from the tasks array to the "type_name" in allWeeksTaskData
const TASK_TYPES = {
  "ATHLETE TASKS": ["Homework Task", "Print and provide"],
  "COACH TASKS": ["Weekly Task", "Print and review"],
  "TEAM ACTIVITIES": ["Team Activity", "Team Discussion"],
  "COACH CORNER": ["Coach Corner"],
};

export default function WeeklyTasks(props) {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [totalWeeksInProgram, setTotalWeeksInProgram] = useState(0);
  const [allWeeksTaskData, setAllWeeksTaskData] = useState([]);
  const [stats, setStats] = useState(null);
  const [coachCornerTasks, setCoachCornerTasks] = useState([]);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState();
  const [completeWeekModalIsOpen, setCompleteWeekModalIsOpen] = useState(false);
  const [programFinishedModalIsOpen, setProgramFinishedModalIsOpen] =
    useState(false);
  const [currDisplayedWeek, setCurrDisplayedWeek] = useState(
    appContext.currWeek
  );
  const [completedTasks, setCompletedTasks] = useState([]);
  const [showUpdateCompletedTaskFailure, setShowUpdateCompletedTaskFailure] =
    useState(false);
  const activeProgram = JSON.parse(localStorage.getItem("activeProgram"));
  const { surveyUrl, handleTypeFormSurveys, onSurveySubmit } = useTypeForm({
    activeProgram,
  });

  useEffect(async () => {
    const handleFormSurveys = async () => {
      const selectedChallenge = await getChallengeData();
      await handleTypeFormSurveys(selectedChallenge);
    };
    handleFormSurveys();
  }, [currDisplayedWeek]);

  useEffect(() => {
    const getChallenges = async () => {
      try {
        const challengeData = await getChallengeData();
        setTotalWeeksInProgram(parseInt(challengeData.data.challenge.weeks));
        // appContext.setCurrWeek(challengeData["current-week"]);
        setAllWeeksTaskData(challengeData["tasks_by_week"]);
        try {
          const jsonCompletedTasks = JSON.parse(
            challengeData["completed_tasks"]
          );
          setCompletedTasks(jsonCompletedTasks);
        } catch (error) {
          console.error("failed to parse completed tasks: ", error);
        }
        // TODO: refactor api
        // Note: crazy stuff has to happen to deal with the poorly structured api response.
        const coachCornerTasks = parseActiveWeekData(
          "COACH CORNER",
          challengeData["tasks_by_week"],
          challengeData["current-week"]
        );
        setCoachCornerTasks(coachCornerTasks);
        const { first_name, last_name, image, email } = JSON.parse(localStorage.getItem("user"));
        const fullName = `${first_name} ${last_name}`;
        setLoggedInUserDetails({ fullName, image, email });
      } catch (error) {
        console.error("failed to parse completed tasks: ", error);
      }
    };

    const getStatsData = async () => {
      try {
        const data = await getStatistics();
        setStats(data);
      } catch (error) {
        console.error("failed to parse completed tasks: ", error);
      }
    };

    getStatsData();
    getChallenges();
  }, [totalWeeksInProgram]);

  useEffect(() => {
    setCurrDisplayedWeek(appContext.currWeek);
  }, [appContext.currWeek]);

  // TODO: refactor api
  // Note: crazy stuff has to happen to deal with the poorly structured api response.
  useEffect(() => {
    const updateCoachCorner = () => {
      const coachCornerTasks = parseActiveWeekData("COACH CORNER");
      setCoachCornerTasks(coachCornerTasks);
    };
    updateCoachCorner();
  }, [currDisplayedWeek]);

  const parseActiveWeekData = (taskTitle, allData, currWeek) => {
    allData = allData ? allData : allWeeksTaskData;
    if (!currDisplayedWeek || allData.length === 0 || taskTitle.trim() === "") {
      return [];
    }

    let flattenedData = [];
    const dataForWeek = allData[`week${currDisplayedWeek}`];
    Object.keys(dataForWeek ?? {}).forEach((key) => {
      flattenedData = flattenedData.concat(dataForWeek[key]);
    });

    const filteredData = flattenedData.filter((task) => {
      return TASK_TYPES[taskTitle].includes(task["type_name"]);
    });

    return filteredData;
  };

  const updateCompletedTasks = async (taskTitle, taskId) => {
    let updatedDemoData = [...completedTasks];

    updatedDemoData[currDisplayedWeek - 1].tasks[taskTitle].forEach((task) => {
      if (task.id === taskId) {
        task.isComplete = 1;
        updatedDemoData[currDisplayedWeek - 1].totalCompleted += 1;
      }
    });

    try {
      await updateCompletedTasksRequest(updatedDemoData);
      setCompletedTasks(updatedDemoData);
    } catch (error) {
      setShowUpdateCompletedTaskFailure(true);
    }
  };

  const proceedToNextWeek = async () => {
    if (appContext.currWeek === totalWeeksInProgram) {
      setProgramFinishedModalIsOpen(true);
      return;
    }
    try {
      await progressToNextWeek();
      appContext.setCurrWeek(appContext.currWeek + 1);
    } catch (error) {
      console.error(error)
    }
  };

  const proceedToNextProgram = async () => {};

  useEffect(() => {
    const inProgress = surveyUrl;
    inProgress
      ? appContext.setSurveyInProgress(true)
      : appContext.setSurveyInProgress(false)
  }, [surveyUrl]);

  const currWeekTitle = currDisplayedWeek 
    ? `Week ${currDisplayedWeek}` 
    : "";
  // if (!currDisplayedWeek) return <></>;

  return (
    <WeeklyTaskContext.Provider
      value={{
        completedTasks,
        updateCompletedTasks,
        loggedInUserDetails,
      }}>
      {!totalWeeksInProgram && (
        <Dialog open={true} classes={{ paper: classes.paper }}>
          <CircularProgress style={{ color: "white", position: 'absolute' }} size={160} />
          <div className="loadingLogo" />
        </Dialog>
      )}
      {surveyUrl 
        ? <TypeFormWrapper onSubmit={onSurveySubmit} surveyUrl={surveyUrl} />
        : <>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={showUpdateCompletedTaskFailure}
              autoHideDuration={5000}
              onClose={() => { setShowUpdateCompletedTaskFailure(false) }}>
              <Alert
                style={{ alignItems: "center" }}
                severity="error"
                action={
                  <React.Fragment>
                    <IconButton aria-label="close" color="inherit"
                      onClick={() => setShowUpdateCompletedTaskFailure(false)}>
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>}>
                Failed to complete the task
              </Alert>
            </Snackbar>

            <ProgramFinishedModal
              setIsOpen={setProgramFinishedModalIsOpen}
              isOpen={programFinishedModalIsOpen}
              proceedToNextProgram={proceedToNextProgram} />
            <CompleteWeekModal
              setIsOpen={setCompleteWeekModalIsOpen}
              isOpen={completeWeekModalIsOpen}
              proceedToNextWeek={proceedToNextWeek} />
            <Content>
              <Grid container spacing={3}>
                <Grid
                  item
                  classes={{ root: classes.root, item: classes.item }}
                  sm={12}>
                  <Nav title={currWeekTitle} subTitle="" user={null} />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} item classes={{ item: classes.item }}>
                  <StyledMobileStepper
                    variant="progress"
                    steps={totalWeeksInProgram 
                      ? totalWeeksInProgram + 1 
                      : 0}
                    position="static"
                    activeStep={currDisplayedWeek}
                    classes={{ progress: classes.progress }}
                    nextButton={
                      <Button
                        disableRipple
                        disableFocusRipple
                        style={{ backgroundColor: "transparent", marginLeft: "5px" }}
                        size="large"
                        onClick={() => {
                          if (currDisplayedWeek === totalWeeksInProgram) {
                            setProgramFinishedModalIsOpen(true);
                          } else if (currDisplayedWeek === appContext.currWeek) {
                            setCompleteWeekModalIsOpen(true);
                          } else {
                            setCurrDisplayedWeek(currDisplayedWeek + 1);
                          }
                        }}
                        disabled={currDisplayedWeek === totalWeeksInProgram + 1}>
                        {currDisplayedWeek === totalWeeksInProgram 
                          ? <>Finish{" "}</>
                          : <>Next{" "}</>}
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button
                        disableRipple
                        disableFocusRipple
                        style={{ backgroundColor: "transparent", marginRight: "5px" }}
                        size="large"
                        onClick={() => { setCurrDisplayedWeek(currDisplayedWeek - 1) }}
                        disabled={currDisplayedWeek <= 1}>
                          <KeyboardArrowLeft />
                          Back
                      </Button>
                    }
                  />
                </Grid>
                <Grid container>
                  <Grid item sm={12} md={12} lg={7}>
                    {tasks.map((task) => {
                      const dataForTask = parseActiveWeekData(task.title);
                      return (
                        <Tasks
                          key={task.title}
                          task={task}
                          data={dataForTask}
                          currDisplayedWeek={currDisplayedWeek}
                        />
                      );
                    })}
                  </Grid>
                  <Grid item sm={false} md={false} lg={1} />
                  <Grid item sm={12} md={12} lg={4} className={classes.rightPane}>
                    <Grid item>
                      <WeeklyCoachCorner activities={coachCornerTasks} />
                    </Grid>
                    <Grid item>
                      {stats 
                        ? <WeeklyTeamProgress
                            stats={stats}
                            completedTasks={completedTasks}
                            currDisplayedWeek={currDisplayedWeek}
                          />
                        : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </>
      }
    </WeeklyTaskContext.Provider>
  );
}
