import ApiInstance from '../../services/api';
import { GET_CHALLENGE_FAILED } from '../challenges';


export const GET_TEAM_STATS_START = 'stats/GET_TEAM_STATS_START';
export const GET_TEAM_STATS_SUCCESS = 'stats/GET_TEAM_STATS_SUCCESS';
export const GET_TEAM_STATS_FAILED = 'stats/GET_TEAM_STATS_FAILED';
export const GET_MEMBER_STATS_START = 'stats/GET_MEMBER_STATS_START';
export const GET_MEMBER_STATS_SUCCESS = 'stats/GET_MEMBER_STATS_SUCCESS';
export const GET_MEMBER_STATS_FAILURE = 'stats/GET_MEMBER_STATS_FAILURE';

const initialState = {
    loading: false,
    error: null,
    weeklyProgress: {
        total_members: 0,
        total_tasks: 0,
        done: 0,
        in_progress: 0,
        not_started: 0,
    },
    memberStats: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_TEAM_STATS_START:
        return {
          ...state,
          loading: true
        };
      case GET_TEAM_STATS_SUCCESS:
        return {
          ...state,
          weeklyProgress: payload.weeklyProgress,
          loading: false,
        };
      case GET_TEAM_STATS_FAILED:
        return {
          ...state,
          error: payload,
          loading: false
        };
      case GET_MEMBER_STATS_START:
        return {
          ...state,
          loading: true,
        }
      case GET_MEMBER_STATS_SUCCESS:
        return {
          ...state,
          loading: false,
          memberStats: payload
        }
      case GET_MEMBER_STATS_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        }
        default: 
            return state;
    }
};

// This action should return team and total progress!
export const getTeamStats = (week) => (dispatch) => {
  let url = 'api/v1/statistic-task';
  if (week) {
    url += `?week=${week}`;    
  }

  dispatch({ type: GET_TEAM_STATS_START });
  ApiInstance.get(url)
    .then(({ data }) => dispatch({
        type: GET_TEAM_STATS_SUCCESS,
        payload: {
          weeklyProgress: data.weekly_progress
        }
    }))
    .catch((err) => dispatch({ type: GET_CHALLENGE_FAILED, payload: err }));
}

export const getMemberStats = (week) => (dispatch) => {
  let url = 'api/v1/statistic-member';
  if (week) {
    url += `?week=${week}`;    
  }

  dispatch({ type: GET_MEMBER_STATS_START });
  ApiInstance.get(url)
    .then(({ data }) => dispatch({ type: GET_MEMBER_STATS_SUCCESS, payload: data }))
    .catch((err) => dispatch({ type: GET_CHALLENGE_FAILED, payload: err }));
}
