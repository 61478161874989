import { Avatar, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { getPercentageOfNumber } from "../../utils/getPercentageOfNumber";
import { useHistory } from "react-router-dom";
import "./memberStats.css";

const useStyles = makeStyles((Theme) =>
  createStyles({
    large: {
      width: Theme.spacing(8),
      height: Theme.spacing(8),
    },
  })
);

export const MemberStats = ({
  firstName = "John",
  lastName = "Doe",
  image,
  weeklyProgress,
  isLastItem = false,
  id,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const memberProgressPercentage = getPercentageOfNumber(
    weeklyProgress.done + weeklyProgress.in_progress,
    weeklyProgress.total_tasks
  );
  return (
    <div className="memberStatsWrapper"
      style={ !isLastItem ? {borderBottom: "1px solid rgba(0, 0, 0, 0.06)"} : {}}>
      <div className="memberStatsProgressBarWrapper">
        <Avatar className={classes.large} src={image} />
        <div className="memberStatsProgressBar"
          onClick={() => history.push(`/reports/athletes/${id}`)}>
          <div className="memberStatsUserInfo">
            {firstName} {lastName}
          </div>
          <div className="memberStatsGraph">
            <div className="memberStatsOrangeGraph"
              style={{ width: `${memberProgressPercentage}%` }} />
          </div>
        </div>
      </div>
      <div className="memberStatsProgressAllWrapper">
        <div className="memberStatsLabel">
          weekly progress - {" "}
          <span className="memberStatsPercentage">
            {Math.round(memberProgressPercentage)}%
          </span>
        </div>
      </div>
    </div>
  );
};
