import React, { useContext, useState } from "react";
import Calendar from "react-calendar";
import Modal from "react-modal";
// style
import "./index.css";
import "../../../index.css";
import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router-dom";

import { createChallenge } from "../../../util/requests/smartCoach";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { AppContext } from "../../../components/App/App";
import ResponseFeedback from "../../../components/Feedback/ResponseFeedback";
import { Dialog, DialogContent } from "@material-ui/core";

const customStyles = {
  content: {
    top: "15%",
    left: "30%",
    padding: "61px",
    height: "450px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#rootModal')

const SelectDateModal = ({
  isOpen,
  closeModal,
  submitDate,
  selectedItemId,
  weeksInProgram,
}) => {
  const history = useHistory();

  const [showConfirmCreate, setShowConfirmCreate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    type: "",
    message: "",
  });

  const appContext = useContext(AppContext);

  const onCreateChallengeSubmit = async (confirmSubmit) => {
    if (weeksInProgram && !confirmSubmit) {
      setShowConfirmCreate(true);
      return;
    }

    try {
      const date = new Date(selectedDate).toISOString().substring(0, 10);
      await createChallenge(selectedItemId, date);
      closeModal();
      appContext.setProgramSelected(true);
      appContext.setCurrWeek(1);
      history.push("/home");
    } catch (error) {
      setFeedbackInfo({
        open: true,
        type: "error",
        message: "An error occured when trying to create the program.",
      });
      setShowConfirmCreate(false);
    }
  };

  if (showConfirmCreate) {
    customStyles.content.height = "auto";
  } else {
    customStyles.content.height = 450;
  }

  return (
    <div>
      <ResponseFeedback
        feedbackInfo={feedbackInfo}
        setFeedbackInfo={setFeedbackInfo}
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setShowConfirmCreate(false);
          closeModal();
        }}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        {showConfirmCreate ? (
          <>
            <div className="logo"></div>
            <div className="modalTitle">Are you sure?</div>
            <div className="modalBody">
              By switching programs your progress will be deleted and you will
              start over from week 1.
            </div>
          </>
        ) : (
          <>
            <div className="selectDateLabel">SELECT START DATE</div>
            <Calendar
              onChange={(value) => setSelectedDate(new Date(value))}
              value={selectedDate}
              minDate={new Date()}
            />
          </>
        )}

        <div className="selectDateButtonsWrapper">
          {showConfirmCreate ? (
            <>
              <div
                onClick={() => {
                  setShowConfirmCreate(false);
                }}
                style={{ background: "#e3e7eb", color: "black" }}
                className="selectDateCloseButton modalBtn"
              >
                BACK
              </div>
              <div
                onClick={() => {
                  onCreateChallengeSubmit(true);
                }}
                className="selectDateSubmitButton modalBtn"
              >
                PROCEED
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  setShowConfirmCreate(false);
                  closeModal();
                }}
                className="selectDateCloseButton modalBtn"
              >
                Close
              </div>
              <div
                onClick={() => {
                  onCreateChallengeSubmit(false);
                }}
                className="selectDateSubmitButton modalBtn"
              >
                Submit
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SelectDateModal;
