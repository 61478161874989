import React, { useContext, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { changeWeek, getCurrentSelectedChallenge } from '../../modules/challenges';
import iconFilterMember from '../../assets/icons/icon_filterMember.png';
import { MemberStats } from '../../components/TeamProgress/MemberStats';
import { Content } from '../Content';
import { Nav } from '../Nav/Nav';
import './index.css';

import ReactPlayer from 'react-player';

const mainActions = {
  changeWeekAction: changeWeek,
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'var(--restoic-black)',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
}));

const useStyles = makeStyles((Theme) =>
  createStyles({
    gridContainerRoot: {
      marginTop: 34,
      backgroundColor: Theme.palette.background.paper,
      padding: "36px 62px",
      borderRadius: "4px",
      boxShadow: "0 18px 18px rgb(25 31 79 / 13%)",
      overflow: "auto",
    },
    courseSelector: {
      font: "600 18px Poppins, sans-serif",
      textTransform: "uppercase",
      color: "var(--restoic-black)",
    }
  }),
);

const ViewCourse = ({
  user: { first_name: firstName, last_name: lastName, image },
  estimatedWeekly = 'Estimated Completion Date: Feb 24, 2022',
  currentWeek = 2,
  memberStats = [
    {
       "user":{
          "id":25,
          "user":{
             "id":14,
             "email":"ian@restoic.com",
             "first_name":"Ian",
             "last_name":"Gulbransen",
             "image":"https://storage.googleapis.com/restoic-media/users/images/dummy1621459004273.jpg?Expires=1644970199&GoogleAccessId=restoic-314023%40appspot.gserviceaccount.com&Signature=id9WriAuXgkMogOBW19V%2BbRsLicZhW5QV7Tj3AfZp7Bhetb1iHzjLkpoIIMO2eAMmTxmdKBh6sJ75obYwJ08ihoMPKvN5ynEakQ8W%2FfFt0B8OJXJvS61wf%2BAPYQGRW96bwnqGLQGm7LFhRdFJbz8ivJF16Wfs5FeBbgbFe3YRO0g4YRzwQ56fGnu6YsO6G2HUfkX0%2BPNprDbceT6T1DcHhOezNSnZsBEik%2FTBj%2B0g5ZFIjuC4HzR6Wok6r4%2FNjlUHDRp57CgYif%2FLm0MLm3FYIHiyjsMcVtzJPEMK%2FyUTddART1ragZC1IOfDbnk1ZRWSDbfEZZ5xxnvS1SJcpg2YQ%3D%3D",
             "premium_to":"2024-12-31",
             "is_coach":true,
             "is_staff":true,
             "usersID":null
          },
          "team":10,
          "role":"Member"
       },
       "weekly":{
          "total_tasks":10,
          "done":2,
          "in_progress":3,
          "not_started":3
       }
    },
    {
       "user":{
          "id":84,
          "user":{
             "id":461,
             "email":"blakedong@gmail.com",
             "first_name":"Restoic",
             "last_name":"Support",
             "image":"https://storage.googleapis.com/restoic-media/users/images/dummy1630353762909.jpg?Expires=1644970199&GoogleAccessId=restoic-314023%40appspot.gserviceaccount.com&Signature=GeEnLTqw24PFnLFS88lg%2BhvJ1Pm2QCSQEhjn5TLsfdZTmhkjubH1kjXE5p4wnXslAUz6abaQ82Gj%2FfIiYAQubpYWneLH5gF21E2nAg9IEW%2FCC6%2BZi5%2Fl%2BJcjH1fiWzuNMmB57%2B1yDf%2FQTCUmegBtd8M%2B3jdrGaDDAuh56MCjUSY7jJ%2B1GwpjGRXGV%2FwdJMICInuUFWrLNcz5iwqIMAOR8sBy2b%2BOnS1i%2Fb5SqCzCoKyMZSil5rS%2BuYEN5qyE9sZxuwXE13DnOPo8cnaAGo88fYbl3ozPGBm8Mcr1up6vmA6yanXOqO5x6q53iJNtIWgFC9CCvv8CfgQKs9NV6uBRhQ%3D%3D",
             "premium_to":"2022-02-06",
             "is_coach":true,
             "is_staff":true,
             "usersID":null
          },
          "team":10,
          "role":"Member"
       },
       "weekly":{
          "total_tasks":10,
          "done":1,
          "in_progress":1,
          "not_started":3
       }
    },
    {
       "user":{
          "id":22,
          "user":{
             "id":30,
             "email":"danny@restoic.com",
             "first_name":"Danny",
             "last_name":"Cavic",
             "image":"https://storage.googleapis.com/restoic-media/users/images/dummy1635175211628.jpg?Expires=1644970199&GoogleAccessId=restoic-314023%40appspot.gserviceaccount.com&Signature=VosYkNQL4OlomfsOr2Ft4OWOGmsxJxaMTIjD6xMy90NXfXgoFsrCBteN2PYDHjsiPAQXjHgqyFUGFeAZ4zNgOq28GzQyV3%2FzeqWpTEX3%2BgunmJ5PLREnb5Ul99UWjJmR2DVoQ%2FFDimyCj8ZlgjaO%2FEYq2pirRD2ZS4uKVlahK4QcYo%2FeckyXtEd3WmFRUkkhfwjBX6%2BvIBcrwlErxe04RS1LLoSUJ8KiBMNj4tDvQLUT5l%2FPrk2ndUDiHi365%2BUgS3iCXRuLMTtiM9sIYLOXxvm7yES4TicTfkEnEiVpv%2FhMPhhYmJox91%2FhNxTINs2pzK%2BExfvAetA0Q9cRxB1TUA%3D%3D",
             "premium_to":"2022-06-21",
             "is_coach":true,
             "is_staff":false,
             "usersID":null
          },
          "team":10,
          "role":"Member"
       },
       "weekly":{
          "total_tasks":10,
          "done":5,
          "in_progress":2,
          "not_started":3
       }
    }
 ],
}) => {
  const classes = useStyles();
  const [sortAction, setSortAction] = useState("Most Active");
  const [allMemberStats, setAllMemberStats] = useState([]);
  const steps = ['1. Introduction', '2. Risk Factors', '3. Communication', '4. Summary'];

  useEffect(() => {
    // initialize page state
    setAllMemberStats(memberStats);
  }, [])

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Content>
      <Grid container>
        <Grid item sm={12}>
          <Nav title="Baseline Assessment" subTitle="Each module will have 1-2 videos and a handout (information and reflection)" />
          {/* <div className="courseProgressWrapper">
            <Stack direction="row" spacing={2} divider={<Divider orientation="horizontal" flexItem/>}>
              <Item>Introduction</Item>
              <Item>Risks factors</Item>
              <Item>Signs and Symptoms</Item>
              <Item>Treatments available</Item>
              <Item>Summary</Item>
            </Stack>
          </div>             */}
        </Grid>
        <Grid container sm={12} classes={{ root: classes.gridContainerRoot }}>
          <Grid item sm={12} md={12}>
            <Grid container spacing={2} direction="column" alignItems="left" justify="left">
              <Grid container spacing={2} direction="column" alignItems="center" justify="center">
               
                <Grid container spacing={2}>
                  <Grid item className="courseGraphSubtitle" md={10}>
                    7 mins – Why does this matter – Understanding the basics of mental health
                  </Grid>
                  <Grid item md={2}>
                      <Button style={{background: "var(--restoic-red)", color: "white" }}>
                        Mark as Done
                      </Button>
                  </Grid>   
                </Grid>            

                <Grid item sm={12}>
                  <Card className="coursePlayerWrapper">
                    <ReactPlayer 
                      // url='https://vimeo.com/294320597' 
                      url='https://vimeo.com/633082581'
                      controls = {true}
                    />
                  </Card>    
                </Grid>      
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <div className="courseMemberWrapper">
              <div className="courseMemberFilterHeader">
                <div className="courseMemberFilterTitle">LEARNING PROGRESS</div>
                <div className="courseFilterWrapper" onClick={() => sortcourse()}>
                  <div className="courseFilterLabel">
                    {sortAction}
                  </div>
                  <img alt="filter" src={iconFilterMember} className="courseFilterIcon" />
                </div>
                <div className="courseDummyDiv" />
              </div>
              <div className="courseMemberStatsWrapper">
                {allMemberStats.map((item, key) => (
                  <MemberStats
                    id={item.user.user.id}
                    key={key}
                    firstName={item.user.user.first_name}
                    lastName={item.user.user.last_name}
                    image={item.user.user.image}
                    weeklyProgress={item.weekly}
                    isLastItem={memberStats.length - 1 === key}
                  />
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Content>
  )
}


export default withRouter(connect(
  ({
    auth: {
      user,
    },
  }) => ({
    user,
  }),
  dispatch => bindActionCreators(mainActions, dispatch)
)(ViewCourse));
