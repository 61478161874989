import { instanceV2 } from "../../services/api";

export async function getTypeformReports(formId) {
  try {
    const req = await instanceV2.get(`v2/reports/typeform/${formId}`);
    return req.data;
  } catch (error) {
    throw error;
  }
}

export async function getAthleteReports(userId) {
  try {
    const req = await instanceV2.get(`v2/reports/athlete/${userId}`);
    return req.data.response;
  } catch (error) {
    throw error;
  }
}
