import axios from "axios";
import { BASE_API_URL, V2_API_URL } from "../config";

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
});
const instanceV2 = axios.create({
  baseURL: V2_API_URL,
  timeout: 10000,
});

const removeTokenFromHeader = () => {
  delete instance.defaults.headers.common["Authorization"];
};

const setTokenIntoHeader = (token) => {
  instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  instanceV2.defaults.headers.common["Authorization"] = `Token ${token}`;
};

export default instance;
export { removeTokenFromHeader, setTokenIntoHeader, instanceV2 };
