import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { IconButton } from '@mui/material'
import { Content } from '../Content';
import DownloadItems from './DownloadItems';
import { getDownloads } from '../../util/requests/smartCoach';
import { Nav } from '../Nav/Nav';
import { AppContext } from '../App/App';
import DownloadIcon from '@mui/icons-material/Download';

export const Downloads = (props) => {
  const [downloadData, setDownloadData] = useState([]);
  const [bulkDownloadLink, setBulkDownloadLink] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const downloads = await getDownloads();
        if (Array.isArray(downloads)) {
          setDownloadData(downloads);
          for (let i = 0; i < downloads.length; i++) {
            if (downloads[i].is_bulk === true) {
              setBulkDownloadLink(downloads[i].file);
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    getData();
  }, [])

  return (
    <Content>
      <Grid container>
        <Grid item sm={12}>
          <div style={{ position: 'relative' }}>
            <Nav title="Downloads" user={null} />
            <div className="downloadButtonBox">
              <h3 style={{ marginRight: '5px' }}>Coach's Guide (All-In-One)</h3>
              <a target="_blank" href={bulkDownloadLink}>
                <IconButton sx={{
                  background: "rgb(220,220,220)",
                  "&:hover": {
                    background: "var(--restoic-red)",
                    color: "white",
                  }
                }}>
                  <DownloadIcon />
                </IconButton>
              </a>
            </div>
          </div>
        </Grid>
        <DownloadItems downloadData={downloadData} />
      </Grid>
    </Content>
  )
}