import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChoosePlanItem from '../../components_old/ChoosePlanItem';
import { closeSelectDateModal, selectPlanAction, selectStartDateForPlan } from '../../modules/challenges';
import './index.css';
import SelectDateModal from '../../components_old/Modals/SelectDateModal';
import CompareModal from '../../components_old/Modals/CompareModal';
import { getChallengeData } from '../../util/requests/smartCoach';
import { AppContext } from '../../components/App/App';

const mainActions = {
	selectPlanAction: selectPlanAction,
	closeSelectDateModalAction: closeSelectDateModal,
	selectStartDateForPlanAction: selectStartDateForPlan
}

const listOfPlans = [
	{
		id: 1,
		numberOfWeeks: '4-Week Program',
		title: 'For teams seeking an introduction to Sports Psychology',
		items: [
			'Full access to a variety of in-app categories including: Sports Psychology, Meditation, Binaural Beats, Breathwork, Soundscapes, and more',
			'Four (4) weekly team activities, homework assignments, and discussion topics focused on Sports Psychology, Meditation, and Binaural Beats',
			'Individual and team progress tracking capabilities + group chat feature'
		],
		recommended: false,
	},
	{
		id: 2,
		numberOfWeeks: '8-Week Program',
		title: 'For teams seeking Sports Psychology and Meditation benefits',
		items: [
			'Full access to a variety of in-app categories including: Sports Psychology, Meditation, Binaural Beats, Breathwork, Soundscapes, and more',
			'Eight (8) weekly team activities, homework assignments, and discussion topics focused on Sports Psychology and Meditation',
			'Individual and team progress tracking capabilities + group chat feature'
		],
		recommended: false,
	},
	{
		id: 3,
		numberOfWeeks: '12-Week Program',
		title: 'For teams seeking a holistic approach to mental health and performance',
		items: [
			'Full access to a variety of in-app categories including: Sports Psychology, Meditation, Binaural Beats, Breathwork, Soundscapes, and more',
			'Twelve (12) weekly team activities, homework assignments, and discussion topics focused on Sports Psychology, Meditation, and Binaural Beats',
			'Individual and team progress tracking capabilities + group chat feature'
		],
		recommended: true,
	}
]

const ChoosePlan = ({
	selectPlanAction,
	first_name,
	modalSelectDate,
	closeSelectDateModalAction,
	selectStartDateForPlanAction
}) => {
	const [compareModal, setCompareModal] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(0);
	const [weeksInProgram, setWeeksInProgram] = useState(0);

	const appContext = useContext(AppContext);
	const programSelected = appContext.programSelected;

	useEffect(() => {
		const getData = async () => {
			try {
				const data = await getChallengeData();
				setWeeksInProgram(data["weeks_in_program"]);
			} catch (error) {
				console.error(error);
			}
		}
		getData();
	}, []);

	let { first_name: firstName } = JSON.parse(localStorage.getItem('user'))
  let title = ""
  !programSelected 
    ? title = `Hi ${firstName}, to get started choose the program that's right for your team` 
    : title = `Hi ${firstName}, to change your program choose from the below options`

	return (
		<div className="choicePlanWrapper">
			<div className="choicePlanTitle">
        <div className="choicePlanLogo" />
        <div className="choicePlanDivider" />
        {title}
      </div>
			<div className="choicePlanItemsWrapper">
				{
					listOfPlans.map((item, key) => {
						let isActiveWeek = false;
						// NOTE: it may be a better idea to store the "numberOfWeeks" as an int in the db...
						if (item.numberOfWeeks === "4-Week Program" && weeksInProgram === 4) {
							isActiveWeek = true;
						} else if (item.numberOfWeeks === "8-Week Program" && weeksInProgram === 8) {
							isActiveWeek = true;
						} else if (item.numberOfWeeks === "12-Week Program" && weeksInProgram === 12) {
							isActiveWeek = true;
						}

						return (
							<ChoosePlanItem
								key={key}
								numberOfWeeks={item.numberOfWeeks}
								title={item.title}
								items={item.items}
								recommended={item.recommended}
								onClick={() => setSelectedItemId(item.id)}
								isActiveWeek={isActiveWeek}
							/>
						)
					})
				}
			</div>
			<div className="choicePlanFooter">
				<div className="choicePlanAlertText">Still have questions?</div>
				<div className="choicePlanAlertButton" onClick={() => setCompareModal(true)}>
					Compare Program Features
				</div>
			</div>
			<SelectDateModal
				selectedItemId={selectedItemId}
				isOpen={selectedItemId}
				closeModal={() => setSelectedItemId(0)}
				submitDate={selectStartDateForPlanAction}
				weeksInProgram={weeksInProgram}
			/>
			<CompareModal
				isOpen={compareModal}
				closeModal={() => setCompareModal(false)}
			/>
		</div>
	);
}

export default withRouter(connect(
	({
		auth: {
			user: {
				first_name
			}
		},
		challenges: {
			modalSelectDate
		}
	}) => ({
		first_name,
		modalSelectDate
	}),
	dispatch => bindActionCreators(mainActions, dispatch)
)(ChoosePlan));

