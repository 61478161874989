import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../components/App/App";
import { getAllTeamMembers } from "../util/requests/smartCoach";
getAllTeamMembers().then((res) =>
  localStorage.setItem("teamId", res.team[0]?.team)
);

const getSurvey = async (id) => {
  const result = await axios.get(
    `https://us-central1-restoic-314023.cloudfunctions.net/cors?url=https://api.typeform.com/forms/${id}`,
    {
      headers: {
        Authorization: `Bearer 5Z4tcptpGNDhn7EvTtx82ZdP2pFGYUDxSd1psUK2GjSs`,
      },
    }
  );

  return result.data;
};

const useTypeForm = ({ activeProgram }) => {
  const [surveyType, setSurveyType] = useState(null);
  const [surveyUrl, setSurveyUrl] = useState(null);
  const appContext = useContext(AppContext);

  const getSurveyUrl = (surveyLink) => {
    const teamId = localStorage.getItem("teamId");
    return `${surveyLink}?name=${appContext.loggedInUserDetails?.fullName}&email=${appContext.loggedInUserDetails?.email}&id=${appContext.loggedInUserDetails?.id}&teamid=${teamId}`;
  };

  const handlePreLogic = async (sameId, surveyId) => {
    const shouldShowPreSurvey = sameId && !activeProgram.preSurveyOpened;
    if (shouldShowPreSurvey && appContext.loggedInUserDetails?.email) {
      const survey = await getSurvey(surveyId);
      if (survey) {
        setSurveyType("pre");
        const surveyLink = getSurveyUrl(survey._links?.display);
        console.log({ surveyLink }, "PRE");
        setSurveyUrl(surveyLink);
      }
    }
  };

  const handlePostLogic = async (sameId, surveyId, currentWeek) => {
    if (activeProgram) {
      const inWeekBeforeLast =
        currentWeek === +activeProgram["weeks_in_program"];
      const shouldShowPostSurvey =
        sameId && !activeProgram.postSurveyOpened && inWeekBeforeLast;
      if (shouldShowPostSurvey && appContext.loggedInUserDetails?.email) {
        const survey = await getSurvey(surveyId);
        if (survey) {
          setSurveyType("post");
          const surveyLink = getSurveyUrl(survey._links?.display);
          setSurveyUrl(surveyLink);
        }
      }
    }
  };

  const handleTypeFormSurveys = async (selectedChallenge) => {
    const surveysIdsMap = {
      pre: "GEJVQjYe",
      post: "jsL3MkAe",
    };
    const sameId = +selectedChallenge?.id === +activeProgram?.id;
    handlePreLogic(sameId, surveysIdsMap.pre);
    handlePostLogic(
      sameId,
      surveysIdsMap.post,
      +selectedChallenge["current-week"]
    );
  };

  const onSurveySubmit = () => {
    localStorage.setItem(
      "activeProgram",
      JSON.stringify({
        ...activeProgram,
        ...(surveyType === "pre" && { preSurveyOpened: true }),
        ...(surveyType === "post" && { postSurveyOpened: true }),
      })
    );
    setSurveyUrl(null);
    setSurveyType(null);
  };

  return {
    surveyUrl,
    handleTypeFormSurveys,
    onSurveySubmit,
  };
};

export default useTypeForm;
